import { Col, Container, Row } from "react-bootstrap";

const LatpayFooter = () => {
    return ( 
        <div className="Latpay-footer mt-5">
            <Container>
                <Row className="p-5">
                    <Col lg={3} md={6} xs={12}><img src="/images/logosmall.png" className="App-logo" alt="logo" />
                    </Col>
                    <Col lg={3} md={6} xs={12}></Col>
                    <Col lg={3} md={6} xs={12}>
                    <h4 className="mt-2">Australia</h4>
                    <div>Unit 2B, 5 Cottonwood Place</div>
                    <div>Oxenford QLD 4210</div>
                    <div>Phone: +61 7 5515 0402</div>
                    <div>Toll Free: 1800 865 224</div>
                    </Col>
                    <Col lg={3} md={6} xs={12}>
                    <h4 className="mt-2">United Kingdom</h4>
                    <div>The Old Church, Quicks Road</div>
                    <div>London, SW19 1EX</div>
                    <div>Phone: +44 (0) 208 5458840</div>
                    <div>Fax: +44 (0) 208 5458839</div>
                    </Col>
                </Row>
            </Container>
        </div> 
    );
}
 
export default LatpayFooter;