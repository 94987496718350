import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LatPayBrand from "../pos/brand";
import DB from "../services/firestoredb";

const ShortCode = () => {
    const params = useParams();
    const code = params.code || "none";

    const [invalid,setInvalid] = useState(false);

    const invalidCode = () => {

    }

    useEffect(() => {
        const redirect = async() => {
            const info = await DB.getShortCode(code);
            
            if (!info.url) {
                // either not valid or expired so display some info
                invalidCode();
            }
            try {
                const url = new URL(info.url);
                if (url.hostname===window.location.hostname) {
                    // local redirect
                    window.location.href = info.url;
                } else {
                    // url redirect
                    window.location.href = info.url;
                }
                // is it same domain? 
            } catch(e) {
                // either not valid or expired so display some info
                invalidCode();
            }

        }
        redirect();
      }, []);

    if (invalid) return LatPayBrand.loadScreen();
    return LatPayBrand.loadScreen();
}
 
export default ShortCode;