import { fb,auth } from "../firebase-config";
import { query, where,collection, getDocs, doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";

const DB = {
    getNav: async () => {
        console.log("Loading Nav!");
        const ref = doc(fb, "web","nav"); 
        const snap = await getDoc(ref);
        const nav = (snap.data());
        console.log(nav);
        return nav;
    },
    createUser: async (email,password) => {
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);
            const user = res.user;
            return {
                success: true,
                user: user,
            };
        } catch (e) {
            return { success: false, error: e.message };
        }
    },
    signIn: async (email,password) => {
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            const user = res.user;
            return {
                success: true,
                user: user,
            };
        } catch (e) {
            return { success: false, error: e.message };
        }
    },
    signOut: async () => {
        await signOut(auth);
    },
    getCompliance: async (uuid) => {
        console.log(`Loading comp info ${uuid}`);
        const ref = doc(fb, `merchInfo/${uuid}/info`,uuid); 
        const snap = await getDoc(ref);
        const compliance = snap.data();
        return compliance;
    },
    getShortCode: async (code) => {
        const d = new Date();
        const now = d.toISOString()
        const ref = query(
            collection(fb,"shortcodes"),
            where("code","==",code),
            where("expires",">=",now)
        );
        // .where("code","==",code);

        const data = await getDocs(ref);
        if (data.docs.length===0) {
            return {};
        } else {
            return data.docs[0].data();
        }
    },
    getPOSKeys: async (merchantId) => {
        const ref = doc(fb, "poskeys",merchantId); 
        const snap = await getDoc(ref);
        const info = snap.data();
        return info;
    },
    getMyPosMerchants: async (uid) => {
        const ref = query(
            collection(fb,"posMerchants"),
            where("admins", "array-contains",uid),
        );

        const data = await getDocs(ref);
        if (data.docs.length===0) {
            return [];
        } else {
            return data.docs.map((d) => {return {...d.data(),id:d.id};});
        }
    },
    getMyMerchants: async (uid) => {
        const ref = query(
            collection(fb,"merchants"),
            where("admins", "array-contains",uid),
        );

        const data = await getDocs(ref);
        if (data.docs.length===0) {
            return [];
        } else {
            return data.docs.map((d) => {return {...d.data(),id:d.id};});
        }
    },
    getPOSInfo: async (merchantId) => {
        console.log(`Loading POS info ${merchantId}`);
        const ref = doc(fb, "pos",merchantId); 
        const snap = await getDoc(ref);
        const info = snap.data();
        console.log(`infos details: ${info.live}`);
        return info;
    },
    getPOSAccInfo: async (merchantId) => {
        console.log(`Loading POS info ${merchantId}`);
        const ref = doc(fb, "posAccountMap",merchantId); 
        const snap = await getDoc(ref);
        const info = snap.data();
        //console.log(`posAccountMap details: ${info.live.paylinkMID} & ${info.live.paylinkPWD}`);
        return info;
    },
    getPOSMerchInfo: async (merchantId, trxId) => {
        console.log(`Loading POS Merch info for ${merchantId}, ${trxId}`);
        const collectionRef = collection(fb, `pos/${merchantId}/paylinks`);
        const q = query(collectionRef, where("trxId", "==", trxId));
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
              console.log(`Merch Info Data not found`);
              return null;
            }
            const matchingDoc = querySnapshot.docs[0];
            const info = matchingDoc.data();
            console.log(`POS Merch details: ${info.plName} & ${info.plMerchantEmail}`);
            return info;
          } catch (error) {
            console.error("Error while fetching data:", error);
            return null;
          }
      },
      getQRMerchInfo: async (merchantId, id) => {
        console.log(`Loading POS Merch info for ${merchantId}, ${id}`);
        const collectionRef = collection(fb, `pos/${merchantId}/trx`);
        const q = query(collectionRef, where("id", "==", id));
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
              console.log(`Merch Info Data not found`);
              return null;
            }
            const matchingDoc = querySnapshot.docs[0];
            const info = matchingDoc.data();
            console.log(`POS Merch details: ${info.plName} & ${info.plMerchantEmail}`);
            return info;
          } catch (error) {
            console.error("Error while fetching data:", error);
            return null;
          }
      },
    getPOSMailInfo: async (merchantId) => {
        console.log(`Loading getPOSMailInfo info ${merchantId}`);
        const ref = doc(fb, `posMerchants/${merchantId}`); 
        const snap = await getDoc(ref);
        const info = snap.data();
        console.log(`posMerchants details: ${info.email}`);
        return info.email;
    },
    getPOSTrx: async (merchantId,trxId) => {
        console.log(`Retrieving Trx info ${merchantId}:${trxId}`);
        const ref = doc(fb, `pos/${merchantId}/trx`,trxId); 
        console.log(`Got Ref`);
        const snap = await getDoc(ref);
        console.log(`Got snap`);
        const info = snap.data();
        //console.log(info);
        return info;
    },
    POSTrxStream: (merchantId,trxId,snapshot,error) => {
        const ref = doc(fb, `pos/${merchantId}/trx`,trxId); 
        const stream = onSnapshot(ref, snapshot,error);
        
        return stream;
    },
    saveDoc: async (data,file) => {
        console.log(`Saving Doc: ${data["id"]}`);
        try {
        var ref = doc(fb,`documents/${data["id"]}`);
        await setDoc(ref,data);
        console.log(`Created: ${data["id"]}`);

        var pg = 0;
        var pgSize = 800000;
        while (file.length>pgSize) {
          const partFile = file.substring(0,pgSize);
          ref = doc(fb,`documents/${data["id"]}/page/${pg}`);
          await setDoc(ref,{"content":partFile});
          console.log(`Added part: ${pg}`);
          pg++;
          file = file.substring(pgSize);
        }
        ref = doc(fb,`documents/${data["id"]}/page/${pg}`);
        await setDoc(ref,{"content":file});
    } catch (e) {
        console.log(e.message);
    }
  }

}

export default DB;