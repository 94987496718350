import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faLink } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import { FloatingLabel, Form, Button, Spinner, Row, Col, Container, Card } from "react-bootstrap";
import { useSearchParams , Link } from "react-router-dom";
import DB from "../services/firestoredb";
import { auth } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import LPAPI from "../services/paylink";
import { createBootstrapComponent } from "react-bootstrap/esm/ThemeProvider";
import LatpayHeader from "../layout/header";
import LatpayCopyright from "../layout/copyright";
import LatpayFooter from "../layout/footer";
import latpayUrl from "../config";

const POSAdmin = () => {
    const download = "https://firebasestorage.googleapis.com/v0/b/latpay-crm.appspot.com/o/LatpayWooCommerce.zip?alt=media&token=6cf06be6-f5d5-4ea1-b117-991c08a2d4eb";

    const docs = {
        "Latpay API": {
            all: {
                info: [
                    "The Latpay API allows you to process payments, check transaction statuses and retrieve reports, in a very simple and consistent manner.",
                    "We have a very simple and quick <a rel='noopener noreferrer' target='_blank' href='https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-2zrTqAEzCz0NXo81lU8E6'>GET STARTED GUIDE</a> that you can follow along with to ensure you are up and running in no time."
                ],
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-2zrTqAEzCz0NXo81lU8E6",
                testKeys: [
                    ["URL",`https://${latpayUrl}/gw/[*uuid*]`],
                    ["Accountid","test_latpay"],
                    ["Storeid","001"],
                    ["Deviceid","01"],
                    //["Storeref","testlatpaystore"],
                    //["Deviceref","testlatpayref"],
                    ["Terminalsecret","testlatpaysec"],
                ]
            },
            "Card Payments": {
                info: ["The Card Payments API is a sub-set of the overall Latpay API so it is important to get an overall understanding first, but if you want to dive straight into Card Payments go right ahead!"],
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-YKTh0AKvoaF9a4849v8hi"
            },
            "Direct Debit/Credit": {
                info: ["The Direct Debit/Credit (also referred to as Bank Payments) API is a sub-set of the overall Latpay API so it is important to get an overall understanding first, but if you are already a Latpay API expert then jump in!"],
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-XUVO-A1TknL7m1wvClFea"
            },
            "Alternative Payments": {
                nfo: ["The Alternative Payments API is a sub-set of the overall Latpay API so it is important to get an overall understanding first, but if you are looking for some universal integration capabilities into country specific solutions then you've come to the right place."],
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-y65J2B8nnDmUf6y-v-abD"
            },
            "EMV": {
                info: ["While we provide a full set of SDKs to work with our terminals, there may be instances where you want more low level access and this is where you'll find the details. THe EMV API is a sub-set of the overall Latpay API so it is important to get an overall understanding first."],
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
        },
        "Terminal SDKs":{
            all: {
                info: ["Here at Latpay we have some SDKs that will assist you with integrating the fully certified <a rel='noopener noreferrer' target='_blank' href='https://latpay.com/terminal/'>IDTECH VP3300</a> terminal for use within your own applications."],
            },
            "iOS SDK": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Android SDK": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Windows SDK": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-35O09msexfLRVkQG7XwXr"
            },
            "MacOS SDK": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Linux SDK": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
        },
        "e-Commerce Solutions": {
            all: {
                info: ["We have some plugins and other pre-created flexible solutions to make it easier for you to integrate the Latpay Payment Solutions into your e-Commerce application."],
            },
            "WooCommerce Plugin": {
                info: ["Using Latpay as your payment solutiong provider has never been this easy! You've completed the hardest part, so from here just follow the simple steps and you'll be taking payments in no time!"],
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-Nv04BuohePrxvV36nXxpy"
            },
            "Hosted Payment Pages": {
                info: ["For those custom solutions or for e-Commerce systems we do not have plugins for yet (tell us, we want to know!) you can use our HPS system. It simply redirects your user to a page you can brand as your own but that lives on our servers so you don't have to worry about the advanced technical details (this is what we're good at!). Once the customer pays, they get passed back to your website/application, often none the wiser!"],
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-Z_BAaFo5ejuFZEQkeLfm8"
            },
        },
        "Payments on the go": {
            all: {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Mobile Terminal": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "QR Payments": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Point of Sale": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Order at Table": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Payment Links": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Invoicing": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
            "Recurring Payments": {
                url: "https://app.archbee.com/public/PREVIEW-qN7fJhSf4kLkxKj9vMHM5/PREVIEW-cy48DkRBuE3OHno6GWfnX"
            },
        }
    }

    const products = [
        { 
            name: "Latpay API",
            code:"api",
            options: [
                "Card Payments",
                "Direct Debit/Credit",
                "Alternative Payments",
                "EMV",
            ],
        },
        { 
            code:"tsdk",
            name: "Terminal SDKs",
            options: [
                "iOS SDK",
                "Android SDK",
                "Windows SDK",
                "MacOS SDK",
                "Linux SDK",
            ],
        },
        { 
            name: "e-Commerce Solutions", 
            code:"ecom",
            options: [
                "WooCommerce Plugin", 
                "Hosted Payment Pages", 
            ],
            
        },
        { 
            name: "Payments on the go",
            code:"lbs",
            options: [
                "Mobile Terminal",
                "QR Payments",
                "Point of Sale",
                "Order at Table",
                "Payment Links",
                "Invoicing",
                "Recurring Payments",
            ],
        },
    ];

    const questions = {
        q: "Does your business do custom software development or are you just looking for a simple payment solution (don't worry, we've got you covered either way!",
        a: [
            {
                btn: "We build our|own software",
                q: "Will you process payments just for you or for sub-merchants also?",
                a: [
                    {
                        btn:"Payments|Just for us",
                        products: [
                            "Latpay API",
                            "Terminal SDKs",
                        ],
                    },
                    {
                        btn:"Multiple|Sub-merchants",
                        products: [
                            "Latpay API",
                            "Terminal SDKs",
                        ],
                    }
                ],
            },
            {
                btn: "We just need|a payment solution",
                q: "Do you provide services to businesses who may require payment facilities?",
                a: [
                    {
                        btn:"Yes we want easy|ways to refer clients",
                        products: [
                            "e-Commerce Solutions",
                            "Payments on the go",
                        ],
                    },
                    {
                        btn:"No, we deal mainly directly with customers",
                        q: "Is your busines a chain or franchise?",
                        a: [
                            {
                                btn:"No",
                                products: [
                                    "e-Commerce Solutions",
                                    "Payments on the go",
                                ],
                            },
                            {
                                btn:"Yes",
                                products: [
                                    "e-Commerce Solutions",
                                    "Payments on the go",
                                ],
                            }
                        ],
                    }
                ],
            }
        ]        
    };

    const [searchParams] = useSearchParams();

    const isReg = searchParams.get("r");
    const isEmbed = searchParams.get("e");
    const preselected = (searchParams.get("s")||"").toLowerCase().split(",").filter((f) => f!=="");

    const timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    const [user, loading, error] = useAuthState(auth);
    const [data, setData] = useState({
        signIn: !isReg,
        showPass: false,
        answers: {}
    });
    const [processing,setProcessing] = useState(false);
    const [signingUp,setsigningUp] = useState(false);
    const [myMerchants,setmyMerchants] = useState([]);
    const [myMerchant,setmyMerchant] = useState();
    const [checking,setchecking] = useState(true);
    //const [verified,setverified] = useState(false);
    const [vData,setvData] = useState();
    const [sortList,setSortList] = useState([]);

    const postData = (cmd,mm) => {
        if (!isEmbed) return;
        if (cmd==="set" && !(myMerchant||mm)) {
            console.log("No Merchant");
            return;
        }
        try {
            window.parent.postMessage({
                cmd: cmd,
                mid: myMerchant?.mid || mm?.mid,
                key: myMerchant?.key || mm?.key,
                height: document.body.scrollHeight + 80
            },"*"); 
        } catch (e) {
            console.log(e.message);
        }
    }

    const receiveMessage = (event) => {
        setchecking(false);
        //console.log("message from host");
        //console.log(event.origin);
        //console.log(event.data);
        if (event.data && event.data.cmd==="get") { //} && myMerchant) {
            event.data.url = event.origin;
            setvData(event.data);
            //setverified(event.data.mid===myMerchant.id && event.data.key === myMerchant.secretKey);
            //setTimeout(() => {
            //    postData("height");
            //},10);
        }
    }

    const handleLinkAccount = () => {
        postData("set");
    }

    const verified = () => {
        if (!myMerchant || !vData) return false;
        return (myMerchant.mid===vData.mid && myMerchant.key===vData.key);
    }

    const checkKeys = async (m) => {
        console.log("Check Keys");
        console.log(myMerchant);
        console.log(vData);
        console.log(verified());
        //if(!myMerchant) return;
        setchecking(true);
        //setverified(false);

        //while (!myMerchant) {
//            console.log("waiting for merchant Info");
            //await timeout(10);
        //}
        postData("get",m);
    }

    const loadKeys = async(u) => {
        if (signingUp) return;
        console.log("Load Keys");
        const uid = u.uid;
        console.log(`UserID: ${uid}`);
        const mm = await DB.getMyPosMerchants(uid);
        //console.log(mm);
        if (mm.length>0) {
            const keys = await DB.getPOSKeys(mm[0].id);
            console.log(keys);
            mm[0].mid = mm[0].id;
            mm[0].key = keys?.terminalSecret;
            setmyMerchant(mm[0]);
            checkKeys(mm[0]);
        } else {
            setmyMerchant();
        }
    }

    const loadMyMerchants = async(u) => {
        if (signingUp) return;
        console.log("Load Merchant Info");
        const uid = u.uid;
        console.log(`UserID: ${uid}`,u);
        const mm = await DB.getMyMerchants(uid);
        //console.log(mm);
        if (mm.length>0) {
            console.log(mm[0]);
            setmyMerchant(mm[0]);
        } else {
            
        }
    }

    const loadLocal = () => {
        try {
            const ldata = localStorage.getItem("info");
            if (ldata) {
                setData(JSON.parse(ldata));
            }
        } catch (e) { }
    }

    const saveLocal = (dx) => {
        try {
        localStorage.setItem("info",JSON.stringify(dx));
        } catch (e) {}
    }

    const breakOut = () => {
        if (window.top.location != document.location) {
            window.top.location.href = document.location.href.split("?")[0];
        }

    }

    useEffect(() => {
        postData("get",{});
        window.addEventListener("message", receiveMessage, false);
        if (!loading) {
            if (user) {
                console.log("logged in");
                console.log(user);
                //loadKeys(user);
                loadLocal();
                loadMyMerchants(user);
            } else {
                console.log("NOT logged in");
            }
        }
        return () => {
            window.removeEventListener("message", receiveMessage);
          };

      }, [user, loading]);

      
    const sortProds = (px) => {
        const pn = px.map((p) => p.name);
        setSortList(pn);
    }
    

    const handleChange = (e) => {
        const dx = {...data};
        dx[e.target.name] = e.target.value;
        if (e.target.name==="name") {
            const na = `${e.target.value}`.toLowerCase().split(" ");
            na.splice(2);
            const n = na.join("");
            dx.shortCode = n.replace(/[^a-z0-9]/gi, '');
        }
        // check for preselected
        if (preselected.length>0 && !dx.products) {
            console.log("settings preselects");
            const l = products.map((p) => p.code);
            console.log("l",l);
            console.log("pre",preselected);
            const px = products.filter((p) => preselected.indexOf(p.code)>=0);
            console.log("px",px);
            if (px.length===0) {
                console.log("nothing found");
                preselected.splice(0);
            } else {
                console.log("setting products");
                dx.products = [...px];

                // now sort products to have the preselected first!
                sortProds(dx.products);
            }
        }
        setData(dx);
    }

    const handleSignIn = async (e) => {
        const dx = {...data};
        dx.error = null;
        setData(dx);
        setProcessing(true);

        const result = await DB.signIn(data.email,data.password);
        dx.error = result.error;
        setData(dx);

        //if (result.success) {
        //    loadKeys(result.user);
        //}
        setProcessing(false);
        breakOut();
    }

    const handleSignOut = async (e) => {
        const dx = {...data};
        dx.error = null;
        dx.password = "";
        setData(dx);
        //setProcessing(true);

        await DB.signOut();

        setProcessing(false);
    }

    const handleSignUp = async (e) => {
        const dx = {...data};
        dx.error = null;
        setData(dx);
        setProcessing(true);
        setsigningUp(true);
        setmyMerchant();
        const result = await DB.createUser(data.email,data.password);
        dx.error = result.error;
        setData(dx);
        const sresult = await DB.signIn(data.email,data.password);
        /*
        setvData({});

        // create 
        const info = await LPAPI.createMerchant(
        {
            "uid":result.user.uid,
            "code":data.shortCode,
            "name":data.name
        });

        try {
            //const mm = (myMerchant && {...myMerchant}) || {};
            const mm = {
                mid : data.shortCode,
                key : info.posKeys.terminalSecret,
            };
            console.log("Setting newly created myMerchant");
            console.log(mm);
            setmyMerchant(mm);
        } catch (e) {

        }
        */

        setProcessing(false);
        setsigningUp(false);
        breakOut();

    }

    const handleToggle = (e) => {
        e.preventDefault();
        const dx = {...data};
        dx.signIn = !dx.signIn;
        setData(dx);
    }

    const validPass = () => {
        if (!data.password && !data.confirm) return "";
        var hasUpper = false;
        var hasLower = false;
        var hasDigit = false;
        var hasChar = false;
        for (const ch of data.password) {
            const upper = ch >= "A" && ch<="Z";
            const lower = ch >= "a" && ch<="z";
            const digit = ch >= "0" && ch<="9";
            const char = !upper && !lower && !digit;
            hasUpper = hasUpper || upper;
            hasLower = hasLower || lower;
            hasDigit = hasDigit || digit;
            hasChar = hasChar || char;
        }
        if (!hasUpper || !hasLower || !hasDigit || !hasChar) {
            const r = [];
            if (!hasUpper) r.push("Uppercase");
            if (!hasLower) r.push("Lowercase");
            if (!hasDigit) r.push("Number");
            if (!hasChar) r.push("Special");
            return `Password requires at least ${r.join(", ")} character`;
        }
        if (data.password.length<8) return "Minimum 8 characters";
        return data.password===data.confirm ? "ok" : "Passwords don't match";
    }

    const shell = (c) => {
        if (isEmbed) {
            let w = 400;
            try {
                w = parseInt(isEmbed);
            } catch (e) {
                w = 400;
            }
        return (<>
            <div className="p-4" style={{maxWidth:w}}>
                {c}
                {/*<div><Link to="#" onClick={handleSignOut}>Sign Out</Link></div>*/}
                {/*<div className="mt-3"><a target="_top" href={document.location.href.split("?")[0]}>x</a></div>*/}
            </div>
            </>
         );
        }
        return <>
            <LatpayHeader />
            <Container  className="py-5 text-start">
                {c}
            </Container>
            <LatpayFooter />
            <LatpayCopyright/>
        </>;
    }



    const handleQuestion = (q,index,a) => {
        //console.log(btn);
        q.a = index;
        q.answer = {question: a};

        const dx = {...data};

        // prep product selection here
        if (a.products) {//(!a.q || !a.a) { // end of line
            try {
                console.log("prepping products");
                const pl = products.filter((n) => a.products.indexOf(n.name)>=0);
                dx.products = [...pl];
                console.log(dx.products);
                sortProds(dx.products);
            } catch (e) {

            }
        }
        
        //dx.questions.custom = "yes";
        setData(dx);
    }

    const handleHeader = (e) => {
        const dx = {...data};
        console.log(e.target.name);
        const name = e.target.name;
        // check if product was selected, remove it
        const px = dx.products.filter((n) =>n.name===name);
        if (px.length>0) {
            // remove it
            const idx = dx.products.indexOf(px[0]);
            dx.products.splice(idx,1);
        } else {
            const pl = products.filter((n) => n.name===name);
            dx.products.push(pl[0]);
        }
        console.log(dx.products);
        setData(dx);
    }
    const handleOption = (e) => {
        try {
            console.log(e.target.name.split("|"));
            const pinfo = e.target.name.split("|");
            const dx = {...data};

            //dx.products = [...pl];
            const pl = dx.products.filter((p) => p.name===pinfo[0]);
            if (pl.length>0) {
                const px = pl[0];
                const idx = px.options.indexOf(pinfo[1]);
                if (idx>=0) {
                    px.options.splice(idx,1);
                } else {
                    px.options.push(pinfo[1]);
                }
                console.log(px);
            }
            

            setData(dx);
        } catch (e) {

        }
    }

    const createMerchant = async () => {
        setProcessing(true);
        const info = await LPAPI.createMerchant(
        {
            "uid":user?.uid,
            "code":data.shortCode,
            "name":data.name,
            "signupInfo":data,
            "products":data.products,
        });

        try {
            //const mm = (myMerchant && {...myMerchant}) || {};
            /*const mm = {
                mid : data.shortCode,
                key : info.posKeys.terminalSecret,
            };
            console.log("Setting newly created myMerchant");
            console.log(mm);
            */
            setmyMerchant(info.merchant);
        } catch (e) {

        }
        setProcessing(false);
    }

    const cb = (name,o) => {
        //return <div style={{width:20, hight:20, display:"inline-block", color:"red"}}></div>;
        return <div className="ms-2"><Form.Check 
            type="checkbox" label={o}
            defaultChecked={true}
            name={`${name}|${o}`}
            onChange={handleOption}
        /></div>;
    }

    const headercb = (name, checked) => {
        //return <div style={{width:20, hight:20, display:"inline-block", color:"red"}}></div>;
        
        return <h5><Form.Check 
            type="checkbox" label={name}
            defaultChecked={checked}
            name={`${name}`}
            onChange={handleHeader}
        /></h5>;
    }

    const selectProducts = (p) => {
        //const pl = products.filter((n) => p.indexOf(n.name)>=0);
        console.log("p",p);
        const px = p.map((x) => x.name);
        const pa = products.filter((p) => sortList.indexOf(p.name)>=0);
        const pb = products.filter((p) => sortList.indexOf(p.name)<0);

        return <>
            <h4 className="text-start mt-5">Excellent!</h4>
            {preselected.length>0 ?
                <div className="text-start">We'd like to recommend the following services. Please feel free to further refine what you are interested in.</div>
            :
                <div className="text-start">Thank you for providing us these insights into your needs. Based on your answers we'd like to recommend the following services. Please feel free to further refine what you are interested in.</div>
            }
            {pa.map((prod) => <div className="text-start mt-3">
                {headercb(prod.name,px.indexOf(prod.name)>=0)}
                {px.indexOf(prod.name)>=0 && prod.options.map((o) => <div>{cb(prod.name,o)}</div>)}
            </div>)}
            {pb.map((prod) => <div className="text-start mt-3">
                {headercb(prod.name,px.indexOf(prod.name)>=0)}
                {px.indexOf(prod.name)>=0 && prod.options.map((o) => <div>{cb(prod.name,o)}</div>)}
            </div>)}
            <div className="d-grid mt-4">
                    {processing?  <Spinner className="mx-auto" animation="border" variant="dark"/> : <Button className="block" variant="indigo" onClick={createMerchant}>Submit</Button> }
            </div>
        </>;
    }

    const questionBuilder = (q) => {
        q.question = q.question || questions;
        //console.log(`qBuilder:`,q);
        try {
            const p = q.question.products;
            if (p || preselected.length>0) {//!q.question.q && !q.question.a) {
                try {
                    //console.log(q);
                    return selectProducts(data.products);
                } catch (e) {
                    return <div>{e.message}</div>;
                }
            }

        return <>
            {!q.answer && <h4 className="text-start mt-3">Q</h4>}
            <div className="text-start">{q.question.q}</div>
            <Row className="my-3">
                {q.question.a.map((a,index) => 
                    <Col key={index} className="d-grid">
                        <Button className="block" variant={q.a===index ? "indigo" : "secondary"} onClick={(e) => handleQuestion(q,index,a)}>{a.btn.split("|").map((b) => <div>{b}</div>)}</Button>
                    </Col>
                )}
            </Row>
            {q.answer && questionBuilder(q.answer)}
        </>;
        } catch (e) {
            console.log("err",e.message);
        }
    }

    const basicInfo = () => {
        return (data.firstName||"").length>1&&(data.lastName||"").length>1&&(data.name||"").length>1;
    }

    const handleBasic = () => {
        if (!basicInfo()) return;
        const dx = {...data};
        dx.basicInfoDone = true;
        setData(dx);
        saveLocal(dx);
    }

    const qCustom = () => {
        console.log("preselected",preselected);
        if (data.basicInfoDone) {
            return shell(<>
                <p>To help identify the most appropriate services we have for you, please answer the following simple questions.</p>
                {questionBuilder(data.answers)}
            </>);
        }
        return shell(<>
        <h3>Welcome!</h3>
            <div className="text-start mb-2">A quick bit about your yourself please?</div>
                <FloatingLabel label="First Name" className="mb-2 text-left">
                    <Form.Control name={`firstName`} value={data.firstName||""} onChange={handleChange} />
                </FloatingLabel>
                <FloatingLabel label="Last Name" className="mb-2 text-left">
                    <Form.Control name={`lastName`} value={data.lastName||""} onChange={handleChange} />
                </FloatingLabel>
                <FloatingLabel label="Business Name" className="mb-2 text-left">
                    <Form.Control name={`name`} value={data.name||""} onChange={handleChange} />
                </FloatingLabel>
                {/*data.name && data.name.length>2 && questionBuilder(data.answers)*/}
                
                
                <Button className="block" variant={basicInfo() ? "indigo" : "secondary"} onClick={(e) => handleBasic()}>Next</Button>
            </>
         );
    }

    const regHeading = () => {
        return <h4>WooCommerce Plugin Registration</h4>;
    }

    const embeddedView = () => {
        console.log(`Embedded View`);
        return !vData ? 
        <div><Spinner className="mx-auto my-3" animation="border" variant="dark"/></div> 
    : 
        <div className="my-3"> 
            {/*<Link to="#" onClick={checkKeys}>Check</Link>*/}
            <div className="mb-3">MerchantID: {myMerchant?.mid}</div>
            {/*<div>Secret Key: {myMerchant?.key}</div>*/}
            {verified() ? 
                <div>
                    <Button variant="mulberry">
                        <FontAwesomeIcon className={`fs-1 me-3 `} icon={faCircleCheck}/>
                        <span className="fs-5">You're all set!</span>
                    </Button>
                </div>
            :
                <div>
                    <Button variant={"indigo"} onClick={handleLinkAccount}>
                        <FontAwesomeIcon className={`fs-3 me-3 `} icon={faLink}/>
                        <span className="fs-5">Link Account</span>
                    </Button>
                </div>
            }
        </div>;
    }

    const standAloneView = () => {
        console.log(`Standalone View`);
        return <div className="my-3"> 
            {/*<div className="mb-3">MerchantID: {myMerchant?.mid}</div>*/}
            <h3>Congratulations!</h3>
            <p>Woocommerce Plugin installation is simple:</p>
            <ol className="text-left">
                <li><b>Download</b> the plugin below.</li>
                <li>In Wordpress, go to <b>Add New Plugin</b> and <b>Upload</b> and <b>Activate</b>.</li>
                <li>Go to <b>WooCommerce Settings</b> | <b>Payments</b>, find Latpay and click Manage.</li>
                <li>Click <b>Link Account</b> and you're all set!</li>
            </ol>
                <div>
                    <a href={download}><Button variant={"indigo"}>
                        <FontAwesomeIcon className={`fs-3 me-3 `} icon={faLink}/>
                        <span className="fs-5">Download Plugin</span>
                    </Button></a>
                </div>
        </div>;
    }

    const merge = (v) => {
        return `${v||""}`.split("[*uuid*]").join(myMerchant.uuid);
    }

    const keyTable = (title,keys) => {
        return <div className="p-2 text-left"><table>
            <thead>
                <tr><th colSpan="2">{title}</th></tr>
            </thead>
            <tbody>{
            keys.map((k,x) => <tr key={x}>{
                k.map((v,x1) => <td key={x1}>{x1===0?<b>{merge(v)}</b> : merge(v)}</td>)
            }</tr>)
        }</tbody></table></div>
    }

    const createMarkup = (c) => {
        return {
            __html: c
        };
    }

    const merchantProduct = (p,x) => {
        const pdocs = docs[p.name];
        return <Card className="mt-3" key={x}>
            <Card.Header as="h5" className="bg-secondary text-light">{p.name}</Card.Header>
            <Card.Body>
            {pdocs.all && <div className="mb-3" >
                {pdocs.all.info && pdocs.all.info.map((i,xx) => <p dangerouslySetInnerHTML={createMarkup(i)}></p>)}
                {pdocs.all.testKeys && keyTable("Your Test Credentials",pdocs.all.testKeys)}
                {pdocs.all.url && <a rel="noopener noreferrer" target="_blank" href={pdocs.all.url}>Documentation</a>}
            </div>}
            <hr/>
            {p.options.map((o,x1) => <div className="mb-3" key={x1}>
                <div><b>{o}</b></div>
                {pdocs[o] && <>
                {pdocs[o].info && pdocs[o].info.map((i,xx) => <p dangerouslySetInnerHTML={createMarkup(i)}></p>)}
                {pdocs[o].url && <a rel="noopener noreferrer" target="_blank" href={pdocs[o].url}>Documentation</a>}
                <hr/>
            </>}
            </div>)}
            </Card.Body>
        </Card>;
    }

    const myMerchantInfo = () => {
        return shell(<>
                <h3>Integration Dashboard</h3>
                <h4>{myMerchant.name}</h4>
                {myMerchant.products.map((p,x) => merchantProduct(p,x))}
        </>);
        /*if (isEmbed) {
            return <div className="text-start p-3 mb-2">
                <h3>Integration Dashboard</h3>
                <h4>{myMerchant.name}</h4>
                {myMerchant.products.map((p,x) => merchantProduct(p,x))}
            </div>;
        }
        return <>
            <LatpayHeader />
            <Container  className="py-5 text-start">
                <h3>Integration Dashboard</h3>
                <h4>{myMerchant.name}</h4>
                {myMerchant.products.map((p,x) => merchantProduct(p,x))}
            </Container>
            <LatpayFooter />
            <LatpayCopyright/>
        </>;*/
    }

    if (isEmbed) postData("height");

    if (myMerchant) {
        return myMerchantInfo();
    }

    if (user) {
        //  questions.
        //  q.custom? 
        //      yes
        //          q.sub?          
        //              no
        //              yes          
        //      no    
        //          q.referrer?
        //              yes
        //              no
        //                  q. chain?
        //                      yes
        //                      no
        return qCustom();
        /*
        switch (data.questions.custom) {
            case "yes":
                return qCustomYes();
            case "no":
                return qCustomNo();
            default:
                return qCustom();
        }*/
        
        
    }

    /*
    if (user) { return (<>
        <div className="p-4" style={{maxWidth:400}}>
            <div className="mb-1"><img src="/images/lat-pay-logo-300x135.png" className="App-logo" alt="logo" /></div>
            
            { isEmbed ? embeddedView() : standAloneView() }
            <div><Link to="#" onClick={handleSignOut}>Sign Out</Link></div>
            
        </div>
        </>
     );
    }
    */

    console.log(`isEmbed: ${isEmbed}`);
    const vp = validPass();
    return (<>
        <div className="p-4" style={{maxWidth:400}}>
            {!isEmbed && <div><img src="/images/lat-pay-logo-300x135.png" className="App-logo" alt="logo" /></div>}
            {/*isReg && regHeading()*/}
            {/*!data.signIn &&
                <>
                <FloatingLabel label="Business Name" className="mb-2 text-left">
                    <Form.Control name={`name`} value={data.name||""} onChange={handleChange} />
                </FloatingLabel>
                </>
                */
            }
            {!data.signIn &&    <h3>Let's get started by creating your FREE account!</h3>}
            
            <FloatingLabel label="Email Address" className="mb-2 text-left">
                <Form.Control name={`email`} value={data.email||""} onChange={handleChange} />
            </FloatingLabel>
            <FloatingLabel label="Password" className="mb-2 text-left">
                    <Form.Control id="pwd" type={data.showPass ? "text":"password"} name={`password`} value={data.password||""} onChange={handleChange} />
            </FloatingLabel>
            {!data.signIn &&
                <>
            <FloatingLabel label="Confirm" className="mb-2 text-left">
                    <Form.Control id="pwd" type={data.showPass ? "text":"password"} name={`confirm`} value={data.confirm||""} onChange={handleChange} />
            </FloatingLabel>
                </>
            }
            {processing ? 
                <Spinner className="mx-auto my-3" animation="border" variant="dark"/>
            :
            data.signIn ? <>
                <div className="d-grid"><Button variant={"indigo"} onClick={handleSignIn}>Sign In</Button></div>
                <div className="mt-3 text-center" style={{fontSize:"smaller"}}>
                    Don't have an account?{" "} 
                    <Link to="/pos/admin/#" onClick={handleToggle}>Create one here</Link>
                </div>
            </> : 
            <>
                {vp&&vp!=="ok" && <span>{vp}</span>}
                <div className="d-grid"><Button variant={vp==="ok"? "indigo" : "secondary"} onClick={handleSignUp}>Create Account</Button></div>
                <div className="mt-3 text-center" style={{fontSize:"smaller"}}>
                    Already have an account?{" "} 
                    <Link to="/pos/admin/#" onClick={handleToggle}>Sign in here</Link>
                </div>
            </>
        }
        </div>
        </>
     );


}
 
export default POSAdmin;