import { useState } from "react";
import { Col, Container, FloatingLabel, Form, Ratio, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import LatpayCopyright from "../layout/copyright";
import CRMFooter from "../layout/crmfooter";
import CRMHeader from "../layout/crmheader";
import LatpayFooter from "../layout/footer";

const WebSocket = () => {
    let socket = new WebSocket("wss://localhost:3013");

socket.onopen = function(e) {
  alert("[open] Connection established");
  alert("Sending to server");
  socket.send("My name is John");
};

socket.onmessage = function(event) {
  alert(`[message] Data received from server: ${event.data}`);
};

socket.onclose = function(event) {
  if (event.wasClean) {
    alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
  } else {
    // e.g. server process killed or network down
    // event.code is usually 1006 in this case
    alert('[close] Connection died');
  }
};

socket.onerror = function(error) {
  alert(`[error] ${error.message}`);
};


    return <>
        <CRMHeader/>
        <Container className="py-5 text-left">
            Websocket
        </Container>
        <CRMFooter/>
    </>;
}

export default WebSocket;