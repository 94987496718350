import { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DB from "../services/firestoredb";
import { auth } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";

const PayFooter = () => {

    return (
        <div className="justify-content-center topHeader py-0 text-muted fixed-bottom">
            Powered by <img src="/images/lat-pay-logo-300x135.png" className="PayFooter-logo" alt="logo" />
        </div>
  );
}
 
export default PayFooter;