import { useState } from "react";
import { Col, Container, FloatingLabel, Form, Ratio, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import LatpayCopyright from "../layout/copyright";
import CRMFooter from "../layout/crmfooter";
import CRMHeader from "../layout/crmheader";
import LatpayFooter from "../layout/footer";

const Search3CX = () => {
    const [searchParams] = useSearchParams();
    let phoneNumber = searchParams.get("phoneNumber");
    let displayName = searchParams.get("displayName");
    // https://latpay.net/search?phoneNumber=+61413996173&displayName=+61413996173
    const [data,setData] = useState({
        "merchant":"panezski",
        "table":"1",
    });

    const handleChange = (e) => {
        const dx = {...data};
        dx[e.target.name] = e.target.value;
        setData(dx);
    }


    return <>
        <CRMHeader/>
        <Container className="py-5 text-left">
            Search {phoneNumber} {displayName}
        </Container>
        <CRMFooter/>
    </>;
}

export default Search3CX;