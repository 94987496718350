import { useEffect, useState } from "react";
import { Alert, Col, Container, Row, Spinner} from "react-bootstrap";
import { useParams } from "react-router-dom";
import LatpayFooter from "../layout/footer";
import LatpayCopyright from "../layout/copyright";
import LatpayHeader from "../layout/header";
import DB from "../services/firestoredb";
import People from "./people";
import DDRSA from "./ddrsa";

const Compliance = () => {
    let params = useParams();
    let merchantId = params.id || "none";
    //const [user, loading, error] = useAuthState(auth);
    const [merchInfo,setMerchInfo] = useState();

    const onPersonChange = (x,p) => {
        const m = {...merchInfo};
        m.people[x] = p;
        setMerchInfo(m);
    }
    const handleDDRSA = () => {
        //
    }

    useEffect(() => {
        const getCompliance = async() => {
            const info = await DB.getCompliance(merchantId);
            console.log(info);
            setMerchInfo(info);
        }
        getCompliance();
      }, []);

    return ( 
        <>
        <LatpayHeader />
        <Container className="py-5 text-left">
            <Row>
                <Col ></Col>
                <Col sm={12} md={10} lg={8} xl={6}>
                <h2>Compliance Requirements</h2>
            <p>In order to finalise your application we need your assistance in obtaining some more information please.</p>
            {merchInfo ? <>
                {merchInfo.ddrsa && !merchInfo.ddrsa.signed ? 
                    <DDRSA info={merchInfo.ddrsa} handleSubmit={handleDDRSA}/>
                :
                    <People list={merchInfo.people} onPersonChange={onPersonChange}/>
                }
            </> : <div className="text-center"><Alert variant="danger"><h3>Loading</h3><h2><Spinner animation="border"/></h2></Alert></div>}
                </Col> 
                <Col></Col>
            </Row>
            
        </Container>
        <LatpayFooter/>
        <LatpayCopyright/>
        </>
     );

}
 
export default Compliance;