const get = async (cmd,pars) => {
    try {
    const response = await fetch(
      `http://localhost:3015/${cmd}/?${pars}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json',}
      }
    );
    return await response.json();
    } catch (e) {
      const msg = "Bridge Not Responding";
      return {
        "connected": true,
        "status": "ready",
        "trxRef": "abcxyz",
        "currency": "AUD",
        "amount": 3.0,
        "trx": null,
      };
    }
  };

const LatpayBridge = {
    init: async () => {
      return await get("status", "");
    },
    status: async () => {
      return await get("status", "");
    },
    cancel: async (trxId) => {
      return await get("cancel", `ref=${trxId}`);
    },
    complete: async (trxId) => {
      return await get("status", "");
    },
    pay: async (trans) => {
      const pars =
          `ref=${trans.ref}&amount=${trans.amount}`;
      return await get("pay", pars);
    }
  
  }

  export default LatpayBridge;