import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { faEye, faEyeSlash, faChevronLeft, faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import DB from "../services/firestoredb";

const LPX = {
    getValue: (target,name) => {
        const na = name.split("-");
        if (na.length===1) {
            return target.value;
        } else {
            switch (na[0]) {
                case "cb":
                    return target.checked;
                default:
                    return target.value;
            }
        }
    },
    getName: (name) => {
        const na = name.split("-");
        return na[na.length-1];
    },
    validContact: (c) => {
        try {
            if ((c.first||"").length<1) return false;
            if ((c.last||"").length<1) return false;
            if (!(c.email||"").match(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)) return false;
            return true;
        } catch (e) {
            return false;
        }
    },
    handleSignIn: (data,e,handleChange) => {
        const ui = {...data};
        ui[e.target.name] = e.target.value;
        handleChange(ui);
    },
    setSignIn: (data,signIn,handleChange) => {
        const ui = {...data};
        ui.signIn = signIn;
        handleChange(ui);
    },
    toggleEye: (data,handleChange) => {
        const ui = {...data};
        ui.showPass = !ui.showPass;
        handleChange(ui);
    },
    signIn: async (data,handleChange) => {
        const result = await DB.signIn(data.email,data.password);
        const ui = {...data};
        ui.signedIn = result.success;// true;
        //ui.signedIn = true;
        handleChange(ui);
    },
    signInCreate: async (data,handleChange) => {
        console.log(`Create: ${ui.signedIn}`);
        const result = await DB.createUser(data.email,data.password);
        console.log(result);
        const ui = {...data};
        ui.signedIn = result.success;// true;
        handleChange(ui);
    },
    toggleSelect: (data,obj,prop,handleChange) => {
        const dx = {...data};
        try {
            dx[obj] = dx[obj]||[];
            const idx = dx[obj].indexOf(prop);
            if (idx>=0) {
                dx[obj].splice(idx,1);
            } else {
                dx[obj].push(prop);
            }
        } catch (e) {
            console.log(e.message);
        }
        handleChange(dx);
    },
}

const LPF = {
    contactInfo: (data,id,handleChange) => {
        return <>
            <Row>
                    <Col>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control name={`contacts|${id}|first`} value={data.contacts[id].first||""} onChange={handleChange} />
                    </Col>
                    <Col>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control name={`contacts|${id}|last`} value={data.contacts[id].last||""} onChange={handleChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control name={`contacts|${id}|email`} value={data.contacts[id].email||""} onChange={handleChange} />
                    </Col>
                </Row>
        </>;
    },
    nextBtns: (step,stepOK,handleNext,handleBack) => {
        if (step>0) {  
            return <Row className="mt-3">
                <Col xs={2} sm={1}><Button className="text-center" variant="light" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft}/></Button></Col>
                <Col xs={10} sm={11}><div className="d-grid"><Button variant={stepOK() ? "indigo" : "secondary"} onClick={handleNext}>{stepOK() ? "Next" : "Please fill required fields"}</Button></div></Col>
            </Row> ;
        }
        return <div className="d-grid mt-3">
                    <Button variant={stepOK() ? "indigo" : "secondary"} onClick={handleNext}>{stepOK() ? "Next" : "Please fill required fields"}</Button>
                </div>;
    },
    handleChange: (e,data) => {
        if (!e) return {...data};
        const na = e.target.name.split("|");
        const dx = {...data};
        switch (na.length) {
            case 3:
                try {
                    dx[na[0]][na[1]][LPX.getName(na[2])] = LPX.getValue(e.target,na[2]);
                } catch (e) {
                    dx[na[0]].push({});
                    dx[na[0]][na[1]][LPX.getName(na[2])] = LPX.getValue(e.target,na[2]);
                }
                break;
            case 2:
                dx[na[0]][LPX.getName(na[1])] = LPX.getValue(e.target,na[1]);
                break;
            default:
                dx[LPX.getName(na[0])] = LPX.getValue(e.target,na[0]);
                break;
        }
        return dx;
    },
    
    userLogin: (data,handleChange) => {
        return <>
                {
                    data.signIn ? <>
                        <h2>Let's get you signed back in!</h2>
                        <p>Sign-in to continue</p>        
                    </>
                    :
                    <>
                        <h2>Let's create your account</h2>
                        <p>Signing up to Latpay is quick and free! No lock-in contracts and no commitment</p>        
                    </>
                }
                <FloatingLabel label="Email Address" className="mb-1">
                    <Form.Control name={`email`} value={data.email||""} onChange={(e) => LPX.handleSignIn(data,e,handleChange)} />
                </FloatingLabel>
                <div className="text-end"><a className="text-secondary" href="#" style={{zIndex:10000}} onClick={() => LPX.toggleEye(data,handleChange)}><FontAwesomeIcon icon={data.showPass ? faEye : faEyeSlash}/></a></div>
                <FloatingLabel label="Password" className="mb-3">
                        <Form.Control id="pwd" type={data.showPass ? "text":"password"} name={`password`} value={data.password||""} onChange={(e) => LPX.handleSignIn(data,e,handleChange)} />
                        <label htmlFor="floatingPasswordCustom">Password</label>
                </FloatingLabel>
                {data.signIn ? <>
                    <div className="d-grid"><Button variant={"indigo"} onClick={() => LPX.signIn(data,handleChange)}>Sign In</Button></div>
                    <div className="mt-3 text-center">
                        Don't have an account? <a href="#" onClick={() => LPX.setSignIn(data,false,handleChange)}>Create one here</a>
                    </div>
                </> : 
                <>
                    <div className="d-grid"><Button variant={"indigo"} onClick={() => LPX.signInCreate(data,handleChange)}>Create Account</Button></div>
                    <div className="mt-3 text-center">
                        Already have an account? <a href="#" onClick={() => LPX.setSignIn(data,true,handleChange)}>Sign in here</a>
                    </div>
                </>
                }
        </>;
    },
    primaryContact: (data,handleChange,okCheck) => {
        if (okCheck) {
            try {
                if (data.contacts.length<1) return false;
                return LPX.validContact(data.contacts[0]);
            } catch (e) {
                return false;
            }
        }
        return <>
            <h2>Merchant Application</h2>
            <p>We'll try and make it as quick and easy as possible! Let's start with your personal details please!</p>
            <Form>
                {LPF.contactInfo(data,0,handleChange)}
            </Form>
        </>;
    },
    /*createAccount: (data,handleChange,okCheck) => {
        if (okCheck) {
            try {
                if (data.contacts.length<1) return false;
                return LPF.validContact(data.contacts[0]);
            } catch (e) {
                return false;
            }
        }
        return <>
            <h2>Let's create your account</h2>
            <p>Signing up to Latpay is quick and free! No lock-in contracts and no commitment</p>
            <Form>
                {LPF.userLogin(data,handleChange)}
            </Form>
        </>;
    },*/
    businessInfo: (data,handleChange,okCheck) => {
        if (okCheck) {
            try {
                if (data.country.length<1) return false;
                if (data.name.length<1) return false;
                return true;
            } catch (e) {
                return false;
            }
        }
        return <>
            <h2>Business Details</h2>
            <p>Tell us a little more about your business operations</p>
            <Form>
                <FloatingLabel className="mb-3" label="Country your business is registered in">
                    <Form.Select name={`country`} value={data.country||""} onChange={handleChange} placeholder="Country">
                        {LPF.countries.map((c) => <option key={c.code} value={c.name}>{c.name}</option>)}
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className="mb-3" label="Trading Name (doing business as)">
                    <Form.Control name={`name`} value={data.name||""} onChange={handleChange}  placeholder="Trading Name"/>
                </FloatingLabel>
                <FloatingLabel className="mb-3" label="Legal Entity Name (if different)">
                    <Form.Control name={`entityName`} value={data.entityName||""} onChange={handleChange} placeholder="Entity Name"/>
                </FloatingLabel>
                <FloatingLabel className="mb-3" label="Legal Entity Type">
                    <Form.Select name={`entityType`} value={data.entityType||""} onChange={handleChange} placeholder="Entity Type">
                        {LPF.entityTypes.map((t,x) => <option key={x} value={t.name}>{t.name}</option>)}
                    </Form.Select>
                </FloatingLabel>
                {/*<FloatingLabel className="mb-3" label="ABN / RCN">
                    <Form.Control name={`abn`} value={data.abn||""} placeholder="Registered Business Number" onChange={handleChange} />
                </FloatingLabel>*/}
                <FloatingLabel className="mb-3" label="Products and/or Services Supplied">
                    <Form.Control name={`natureOfBusiness`} value={data.natureOfBusiness||""} onChange={handleChange}  placeholder="Products"/>
                </FloatingLabel>
                <FloatingLabel className="mb-3" label="Website">
                    <Form.Control name={`url`} value={data.url||""} onChange={handleChange}  placeholder="Website"/>
                </FloatingLabel>
            </Form>
        </>;
    },
    paytypes: [
        {
            id: "inperson",
            name: "In person payments",
            desc: "In person payments are payments where the customer is physically present and pays on a certified payment terminal.",
        },
        {
            id: "online",
            name: "Online payments through a website or app",
            desc: "Online payments may be paying an invoice or on checkout at your online store.",
        },
        {
            id: "recurring",
            name: "Recurring payments via bank account or card",
            desc: "Customers register their details and you charge them a pre-agreed weekly or monthly fee, or youy may make payments regularly to someone.",
        },
    ],
    services: [
        {
            name: "Hosted Payment Page",
            desc: "The quickest and easiest way to implement payments for your websites and apps, including the ability to accept Apple and Google Pay!",
            doc: "https://docs.latpay.net"
        },
        {
            name: "Shopping Cart Plugin",
            desc: "Give customers a great check-out experience with our plugins!",
            doc: "https://docs.latpay.net"
        },
        {
            name: "Direct Debit",
            desc: "Take regular direct debit and recurring card payments from your customers at predetermined times.",
            doc: "https://docs.latpay.net"
        },
        {
            name: "Paylink / SMSpay",
            desc: "Quick and easy payments on the go. Send invoices immediately via simple payment link.",
            doc: "https://docs.latpay.net"
        },
        {
            name: "Recurring Payments",
            desc: "Set up scheduled payments for subscription billing.",
            doc: "https://docs.latpay.net"
        },
    ],
    testTypes: (data,handleChange,okCheck) => {
        if (okCheck) {
            try {
                return (data.services||[]).length>0;
            } catch (e) {
                return false;
            }
        }
        const isSelected = (s) => {
            return (data.services||[]).indexOf(s.name)>=0;
        }
        return <>
            <h2>Payment Solutions</h2>
            <p>Which of the following payment solutions would you like to try out?</p>
                <div className="p-3 d-grid gap-2">
                    {LPF.services.map((s,x) => 
                        <Button key={x} variant={isSelected(s) ? "mulberry":"secondary"} className="text-start mb-2" onClick={() => LPX.toggleSelect(data,"services",s.name,handleChange)}>
                            <FontAwesomeIcon className={`fs-1 me-3 ${isSelected(s) ? "" : "opacity-25"}`} icon={faCircleCheck}/>
                            <span className="fs-5">{s.name}</span>
                        </Button>
                    )}
                </div>
        </>;
    },
    paymentTypes: (data,handleChange,okCheck) => {
        /*if (okCheck) {
            try {
                let c = false ;
                for (const t of LPF.paytypes) {
                    c = c || data.payReq[t.id];
                }
                return c;
            } catch (e) {
                return false;
            }
        }*/
        if (okCheck) {
            try {
                return (data.paytypes||[]).length>0;
            } catch (e) {
                return false;
            }
        }
        const isSelected = (s) => {
            return (data.paytypes||[]).indexOf(s.name)>=0;
        }
        return <>
            <h2>Payment Requirements</h2>
            <p>Tell us about the type of payments you wish to process.</p>
                <div className="p-3 d-grid gap-2">
                    {/*LPF.paytypes.map((t) => 
                        <div className="mb-3">
                        <Form.Check 
                            type="checkbox"
                            name={`payReq|cb-${t.id}`}
                            defaultChecked={data.payReq[t.id]||false}
                            label={t.name}
                            onChange={handleChange}
                        />
                        <div className="px-4 infoNote">{t.desc}</div>
                    </div>
                    )*/}
                    {LPF.paytypes.map((s,x) => 
                        <Button key={x} variant={isSelected(s) ? "mulberry":"secondary"} className="text-start mb-2" onClick={() => LPX.toggleSelect(data,"paytypes",s.name,handleChange)}>
                            <FontAwesomeIcon className={`fs-1 me-3 ${isSelected(s) ? "" : "opacity-25"}`} icon={faCircleCheck}/>
                            <span className="fs-5">{s.name}</span>
                        </Button>
                    )}
                </div>
        </>;
    },
    stakeHolders: (data,handleChange,okCheck) => {
        if (okCheck) {
            try {
                return data.payReq.terminal||data.payReq.online||data.payReq.recurring;
            } catch (e) {
                return false;
            }
        }
        const handleAddDirector = () => {
            data.contacts.push({director:true});
            handleChange();
        }

        const legalName = data.entityName||data.name;
        const dirs = data.contacts.filter((c,x) => x>0);
        const stakeholders = [...data.stakeHolders||[],{}];
        const typeInfo = [...LPF.entityTypes.filter((t) => t.name===data.entityType),{}][0];

        const remove = (x) => {
            try {
                data.stakeHolders.splice(x,1);
                handleChange({...data});
            } catch (e) {}
        }

        const shRow = (x,sh) => {
            const setOption = (o) => {
                sh[o] = !sh[o];
                handleChange({...data});
            }
            return <tr key={x}>
                <td><Form.Control name={`stakeHolders|${x}|name`} value={sh.name||""} onChange={handleChange} /></td>
                <td><Form.Select name={`stakeHolders|${x}|type`} value={sh.type||""} onChange={handleChange}>
                        {LPF.entityTypes.map((t,x) => <option key={x} value={t.name}>{t.name}</option>)}
                    </Form.Select>
                </td>
                <td>
                    {(typeInfo.options||[]).map((o,x) => <Badge className="me-1 mb-1 clickable" bg={sh[o] ? "warning":"light"} text={sh[o] ? "dark":"muted"} key={x} onClick={() => setOption(o)}>{o}</Badge>)}
                </td>
                <td><a href="#" onClick={() => remove(x)} className="text-muted"><FontAwesomeIcon icon={faXmark}/></a></td>
            </tr>;
        }   
        console.log(data.entityType);
        console.log(typeInfo);

        return <>
                    <h2>Business StakeHolders</h2>
                    <p>You've indicated that <b>{legalName}</b> is a <b>{data.entityType}</b>. {typeInfo.msg} Any information you can't supply right now will need to be supplied prior to your account going live.</p>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Roles</th>
                                <th></th>
                                {/*
                                    (typeInfo.options||[]).map((o,x) => <th key={x}>{o}</th>)
                                */}
                            </tr>
                        </thead>
                        <tbody>
                            {stakeholders.map((s,x) =>  shRow(x,s))} 
                            {/*<tr>
                                <td>{data.contacts[0].first}</td>
                                <td>{data.contacts[0].last}</td>
                                <td>{data.contacts[0].email}</td>
                                <td>
                                <Form.Check 
                                    type="checkbox"
                                    name={`contacts|0|cb-director`}
                                    defaultChecked={data.contacts[0].director||false}
                                    onChange={handleChange}
                                />
                                </td>
                            </tr>
                            {dirs.map((d,x) => 
                                <tr key={x}>
                                    <td><Form.Control name={`contacts|${x+1}|first`} value={d.first||""} onChange={handleChange} /></td>
                                    <td><Form.Control name={`contacts|${x+1}|last`} value={d.last||""} onChange={handleChange} /></td>
                                    <td><Form.Control name={`contacts|${x+1}|email`} value={d.email||""} onChange={handleChange} /></td>
                                    <td>
                                    <Form.Check 
                                        type="checkbox"
                                        name={`contacts|${x+1}|cb-director`}
                                        defaultChecked={d.director||false}
                                        onChange={handleChange}
                                    />
                                    </td>
                                </tr>
                            )}
                            */}
                        </tbody>
                    </Table>              
                    {/*<Button variant="light" onClick={handleAddDirector}>+ Add Director</Button>*/}
                </>;
        
    },
    allDoneTestAcc: (data,okCheck) => {
        if (okCheck) {
            return "done";
        }
        const isSelected = (s) => {
            return (data.services||[]).indexOf(s.name)>=0;
        }
        return <>
            <h1>All Done!</h1>
            <p>As soon as your test account is approved (don't worry, it only takes a few minutes!), you'll receive an email with your test accountials for the services you requested. In the mean time we've listed links to our technical integration information which will also be inlcuded in the email.</p>
            {
                LPF.services
                .filter((s) => isSelected(s))
                .map((s,x) => 
                    <div key={x} className="mb-2">
                        <h3>{s.name}</h3>
                        <p>{s.desc}</p>
                        <p><a href={s.doc}>Documentation</a></p>
                    </div>
                )}
            <p>We can't wait to see what you implement with Latpay. Please let us know if you have any issues!</p>
            <p>Best regards, the <b>Team @ Latpay</b></p>
        </>;
    },
    allDoneApplication: (data,okCheck) => {
        if (okCheck) {
            return "done";
        }
        /*const isSelected = (s) => {
            return (data.services||[]).indexOf(s.name)>=0;
        }*/
        return <>
            <h1>All Done!</h1>
            <p>Thank you for submitting your application. Our team will very shortly be reviewing the information you supplied and we hope to get you up and running in no time!</p>
            <p>We look forward to working with you. Please let us know if you have any issues!</p>
            <p>Best regards, the <b>Team @ Latpay</b></p>
        </>;
    },
    sn: {
        primaryContact: "primaryContact",
        businessInfo: "businessInfo",
        paymentTypes: "paymentTypes",
        testTypes: "testTypes",
        stakeHolders: "stakeHolders",
        allDoneTestAcc: "allDoneTestAcc",
        allDoneApplication: "allDoneApplication",
    },
    entityTypes: [
        { 
            name: "Pty Ltd",
            msg: "Please list all directors and shareholders with 25% or more shares in the company.",
            options: ["Director","Shareholder"],
            // please list directors or individual shareholders above 25%
            // get directors
            // get shareholders above 25 % (person or company or trust or partnership)
        },
        { 
            name: "Trust",
            msg: "Please list all trustees, beneficiaries, trustees, settlors and appointors.",
            options: ["Trustee","Beneficiary","Settlor","Appointor"],
            // trustees (person or company or trust or partnership)
            // beneficiaries persons
            // settlor(s) persons
            // Appointor person
        },
        { 
            name: "Sole Proprietor",
            // just you
        },
        { 
            name: "Charity",
            // just you
        },
        { 
            name: "Partnership",
            msg: "Please list all partners.",
            // partners, like shareholders and trustees
        },
        { 
            name: "Public Company",
            // just you
        },
        { 
            name: "Not For Profit",
            // just you
        },
        { 
            name: "Other"
            // just you
        },
    ],
    countries: [ 
        {name: 'Australia', code: 'AU'}, 
        {name: 'United Kingdom', code: 'GB'}, 
        {name: 'Afghanistan', code: 'AF'}, 
        {name: 'Åland Islands', code: 'AX'}, 
        {name: 'Albania', code: 'AL'}, 
        {name: 'Algeria', code: 'DZ'}, 
        {name: 'American Samoa', code: 'AS'}, 
        {name: 'AndorrA', code: 'AD'}, 
        {name: 'Angola', code: 'AO'}, 
        {name: 'Anguilla', code: 'AI'}, 
        {name: 'Antarctica', code: 'AQ'}, 
        {name: 'Antigua and Barbuda', code: 'AG'}, 
        {name: 'Argentina', code: 'AR'}, 
        {name: 'Armenia', code: 'AM'}, 
        {name: 'Aruba', code: 'AW'}, 
        {name: 'Austria', code: 'AT'}, 
        {name: 'Azerbaijan', code: 'AZ'}, 
        {name: 'Bahamas', code: 'BS'}, 
        {name: 'Bahrain', code: 'BH'}, 
        {name: 'Bangladesh', code: 'BD'}, 
        {name: 'Barbados', code: 'BB'}, 
        {name: 'Belarus', code: 'BY'}, 
        {name: 'Belgium', code: 'BE'}, 
        {name: 'Belize', code: 'BZ'}, 
        {name: 'Benin', code: 'BJ'}, 
        {name: 'Bermuda', code: 'BM'}, 
        {name: 'Bhutan', code: 'BT'}, 
        {name: 'Bolivia', code: 'BO'}, 
        {name: 'Bosnia and Herzegovina', code: 'BA'}, 
        {name: 'Botswana', code: 'BW'}, 
        {name: 'Bouvet Island', code: 'BV'}, 
        {name: 'Brazil', code: 'BR'}, 
        {name: 'British Indian Ocean Territory', code: 'IO'}, 
        {name: 'Brunei Darussalam', code: 'BN'}, 
        {name: 'Bulgaria', code: 'BG'}, 
        {name: 'Burkina Faso', code: 'BF'}, 
        {name: 'Burundi', code: 'BI'}, 
        {name: 'Cambodia', code: 'KH'}, 
        {name: 'Cameroon', code: 'CM'}, 
        {name: 'Canada', code: 'CA'}, 
        {name: 'Cape Verde', code: 'CV'}, 
        {name: 'Cayman Islands', code: 'KY'}, 
        {name: 'Central African Republic', code: 'CF'}, 
        {name: 'Chad', code: 'TD'}, 
        {name: 'Chile', code: 'CL'}, 
        {name: 'China', code: 'CN'}, 
        {name: 'Christmas Island', code: 'CX'}, 
        {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {name: 'Colombia', code: 'CO'}, 
        {name: 'Comoros', code: 'KM'}, 
        {name: 'Congo', code: 'CG'}, 
        {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {name: 'Cook Islands', code: 'CK'}, 
        {name: 'Costa Rica', code: 'CR'}, 
        {name: 'Cote D\'Ivoire', code: 'CI'}, 
        {name: 'Croatia', code: 'HR'}, 
        {name: 'Cuba', code: 'CU'}, 
        {name: 'Cyprus', code: 'CY'}, 
        {name: 'Czech Republic', code: 'CZ'}, 
        {name: 'Denmark', code: 'DK'}, 
        {name: 'Djibouti', code: 'DJ'}, 
        {name: 'Dominica', code: 'DM'}, 
        {name: 'Dominican Republic', code: 'DO'}, 
        {name: 'Ecuador', code: 'EC'}, 
        {name: 'Egypt', code: 'EG'}, 
        {name: 'El Salvador', code: 'SV'}, 
        {name: 'Equatorial Guinea', code: 'GQ'}, 
        {name: 'Eritrea', code: 'ER'}, 
        {name: 'Estonia', code: 'EE'}, 
        {name: 'Ethiopia', code: 'ET'}, 
        {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {name: 'Faroe Islands', code: 'FO'}, 
        {name: 'Fiji', code: 'FJ'}, 
        {name: 'Finland', code: 'FI'}, 
        {name: 'France', code: 'FR'}, 
        {name: 'French Guiana', code: 'GF'}, 
        {name: 'French Polynesia', code: 'PF'}, 
        {name: 'French Southern Territories', code: 'TF'}, 
        {name: 'Gabon', code: 'GA'}, 
        {name: 'Gambia', code: 'GM'}, 
        {name: 'Georgia', code: 'GE'}, 
        {name: 'Germany', code: 'DE'}, 
        {name: 'Ghana', code: 'GH'}, 
        {name: 'Gibraltar', code: 'GI'}, 
        {name: 'Greece', code: 'GR'}, 
        {name: 'Greenland', code: 'GL'}, 
        {name: 'Grenada', code: 'GD'}, 
        {name: 'Guadeloupe', code: 'GP'}, 
        {name: 'Guam', code: 'GU'}, 
        {name: 'Guatemala', code: 'GT'}, 
        {name: 'Guernsey', code: 'GG'}, 
        {name: 'Guinea', code: 'GN'}, 
        {name: 'Guinea-Bissau', code: 'GW'}, 
        {name: 'Guyana', code: 'GY'}, 
        {name: 'Haiti', code: 'HT'}, 
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {name: 'Holy See (Vatican City State)', code: 'VA'}, 
        {name: 'Honduras', code: 'HN'}, 
        {name: 'Hong Kong', code: 'HK'}, 
        {name: 'Hungary', code: 'HU'}, 
        {name: 'Iceland', code: 'IS'}, 
        {name: 'India', code: 'IN'}, 
        {name: 'Indonesia', code: 'ID'}, 
        {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {name: 'Iraq', code: 'IQ'}, 
        {name: 'Ireland', code: 'IE'}, 
        {name: 'Isle of Man', code: 'IM'}, 
        {name: 'Israel', code: 'IL'}, 
        {name: 'Italy', code: 'IT'}, 
        {name: 'Jamaica', code: 'JM'}, 
        {name: 'Japan', code: 'JP'}, 
        {name: 'Jersey', code: 'JE'}, 
        {name: 'Jordan', code: 'JO'}, 
        {name: 'Kazakhstan', code: 'KZ'}, 
        {name: 'Kenya', code: 'KE'}, 
        {name: 'Kiribati', code: 'KI'}, 
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {name: 'Korea, Republic of', code: 'KR'}, 
        {name: 'Kuwait', code: 'KW'}, 
        {name: 'Kyrgyzstan', code: 'KG'}, 
        {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {name: 'Latvia', code: 'LV'}, 
        {name: 'Lebanon', code: 'LB'}, 
        {name: 'Lesotho', code: 'LS'}, 
        {name: 'Liberia', code: 'LR'}, 
        {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {name: 'Liechtenstein', code: 'LI'}, 
        {name: 'Lithuania', code: 'LT'}, 
        {name: 'Luxembourg', code: 'LU'}, 
        {name: 'Macao', code: 'MO'}, 
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {name: 'Madagascar', code: 'MG'}, 
        {name: 'Malawi', code: 'MW'}, 
        {name: 'Malaysia', code: 'MY'}, 
        {name: 'Maldives', code: 'MV'}, 
        {name: 'Mali', code: 'ML'}, 
        {name: 'Malta', code: 'MT'}, 
        {name: 'Marshall Islands', code: 'MH'}, 
        {name: 'Martinique', code: 'MQ'}, 
        {name: 'Mauritania', code: 'MR'}, 
        {name: 'Mauritius', code: 'MU'}, 
        {name: 'Mayotte', code: 'YT'}, 
        {name: 'Mexico', code: 'MX'}, 
        {name: 'Micronesia, Federated States of', code: 'FM'}, 
        {name: 'Moldova, Republic of', code: 'MD'}, 
        {name: 'Monaco', code: 'MC'}, 
        {name: 'Mongolia', code: 'MN'}, 
        {name: 'Montserrat', code: 'MS'}, 
        {name: 'Morocco', code: 'MA'}, 
        {name: 'Mozambique', code: 'MZ'}, 
        {name: 'Myanmar', code: 'MM'}, 
        {name: 'Namibia', code: 'NA'}, 
        {name: 'Nauru', code: 'NR'}, 
        {name: 'Nepal', code: 'NP'}, 
        {name: 'Netherlands', code: 'NL'}, 
        {name: 'Netherlands Antilles', code: 'AN'}, 
        {name: 'New Caledonia', code: 'NC'}, 
        {name: 'New Zealand', code: 'NZ'}, 
        {name: 'Nicaragua', code: 'NI'}, 
        {name: 'Niger', code: 'NE'}, 
        {name: 'Nigeria', code: 'NG'}, 
        {name: 'Niue', code: 'NU'}, 
        {name: 'Norfolk Island', code: 'NF'}, 
        {name: 'Northern Mariana Islands', code: 'MP'}, 
        {name: 'Norway', code: 'NO'}, 
        {name: 'Oman', code: 'OM'}, 
        {name: 'Pakistan', code: 'PK'}, 
        {name: 'Palau', code: 'PW'}, 
        {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {name: 'Panama', code: 'PA'}, 
        {name: 'Papua New Guinea', code: 'PG'}, 
        {name: 'Paraguay', code: 'PY'}, 
        {name: 'Peru', code: 'PE'}, 
        {name: 'Philippines', code: 'PH'}, 
        {name: 'Pitcairn', code: 'PN'}, 
        {name: 'Poland', code: 'PL'}, 
        {name: 'Portugal', code: 'PT'}, 
        {name: 'Puerto Rico', code: 'PR'}, 
        {name: 'Qatar', code: 'QA'}, 
        {name: 'Reunion', code: 'RE'}, 
        {name: 'Romania', code: 'RO'}, 
        {name: 'Russian Federation', code: 'RU'}, 
        {name: 'RWANDA', code: 'RW'}, 
        {name: 'Saint Helena', code: 'SH'}, 
        {name: 'Saint Kitts and Nevis', code: 'KN'}, 
        {name: 'Saint Lucia', code: 'LC'}, 
        {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {name: 'Samoa', code: 'WS'}, 
        {name: 'San Marino', code: 'SM'}, 
        {name: 'Sao Tome and Principe', code: 'ST'}, 
        {name: 'Saudi Arabia', code: 'SA'}, 
        {name: 'Senegal', code: 'SN'}, 
        {name: 'Serbia and Montenegro', code: 'CS'}, 
        {name: 'Seychelles', code: 'SC'}, 
        {name: 'Sierra Leone', code: 'SL'}, 
        {name: 'Singapore', code: 'SG'}, 
        {name: 'Slovakia', code: 'SK'}, 
        {name: 'Slovenia', code: 'SI'}, 
        {name: 'Solomon Islands', code: 'SB'}, 
        {name: 'Somalia', code: 'SO'}, 
        {name: 'South Africa', code: 'ZA'}, 
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {name: 'Spain', code: 'ES'}, 
        {name: 'Sri Lanka', code: 'LK'}, 
        {name: 'Sudan', code: 'SD'}, 
        {name: 'Suriname', code: 'SR'}, 
        {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {name: 'Swaziland', code: 'SZ'}, 
        {name: 'Sweden', code: 'SE'}, 
        {name: 'Switzerland', code: 'CH'}, 
        {name: 'Syrian Arab Republic', code: 'SY'}, 
        {name: 'Taiwan, Province of China', code: 'TW'}, 
        {name: 'Tajikistan', code: 'TJ'}, 
        {name: 'Tanzania, United Republic of', code: 'TZ'}, 
        {name: 'Thailand', code: 'TH'}, 
        {name: 'Timor-Leste', code: 'TL'}, 
        {name: 'Togo', code: 'TG'}, 
        {name: 'Tokelau', code: 'TK'}, 
        {name: 'Tonga', code: 'TO'}, 
        {name: 'Trinidad and Tobago', code: 'TT'}, 
        {name: 'Tunisia', code: 'TN'}, 
        {name: 'Turkey', code: 'TR'}, 
        {name: 'Turkmenistan', code: 'TM'}, 
        {name: 'Turks and Caicos Islands', code: 'TC'}, 
        {name: 'Tuvalu', code: 'TV'}, 
        {name: 'Uganda', code: 'UG'}, 
        {name: 'Ukraine', code: 'UA'}, 
        {name: 'United Arab Emirates', code: 'AE'}, 
        {name: 'United States', code: 'US'}, 
        {name: 'United States Minor Outlying Islands', code: 'UM'}, 
        {name: 'Uruguay', code: 'UY'}, 
        {name: 'Uzbekistan', code: 'UZ'}, 
        {name: 'Vanuatu', code: 'VU'}, 
        {name: 'Venezuela', code: 'VE'}, 
        {name: 'Viet Nam', code: 'VN'}, 
        {name: 'Virgin Islands, British', code: 'VG'}, 
        {name: 'Virgin Islands, U.S.', code: 'VI'}, 
        {name: 'Wallis and Futuna', code: 'WF'}, 
        {name: 'Western Sahara', code: 'EH'}, 
        {name: 'Yemen', code: 'YE'}, 
        {name: 'Zambia', code: 'ZM'}, 
        {name: 'Zimbabwe', code: 'ZW'} 
      ],
};

export default LPF;