import { Alert, Button, Card, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import LatpayFooter from "../layout/footer";
import LatpayCopyright from "../layout/copyright";
import LatpayHeader from "../layout/header";
import loadLatpayJS from "./loadlatpay2";
import loadjQuery from "./loadjquery";
import { useEffect, useState } from "react";
import { sha1 } from 'crypto-hash';
import latpayUrl from "../config";

const HPSDemo = () => {

    const account = {
        merchantuserid:"test_latpay",
        publickey:"querygfdsazau",
    }

    const embFields = [
        "amount",
        "currency",
        "reference",
        "description",
        "firstname",
        "lastname",
        "email"
    ];
    const accFields = [
        "merchantuserid",
        "publickey",
    ];

    const defaulEmbed = {
        amount: "0.05",
        currency: "GBP",
        reference: "373A" ,
        description: "Website Purchase 2",
        firstname: 'Demo',
        lastname: 'User',
        email: 'demo.user@latpay.com',
        myserver: `https://${latpayUrl}/api/hps?t=embedded`,
    }

    const defaultData = [
        {
            "name":"Merchant_User_Id",
            "value":"test_latpay",
            "hidden": true,
        },
        {
            "name":"secret",
            "value":"SALT",
            "exclude": true,
        },
        {
            "name":"merchant_ref_number",
            "value":"3739",
            "hidden": true,
        },
        {
            "name":"currencydesc",
            "value":"GBP",
            "hidden": true,
        },
        {
            "name":"amount",
            "value":"5.00",
            "hidden": true,
        },
        {
            "name":"transactionkey",
            "value":"",
            "hidden": true,
            "calculated": true
        },
        /*{
            "name":"merchantpwd",
            "value":"kfRqeTkwcbM6c3IZDXHL",
            "hidden": true,
        },*/
        {
            "name":"Purchase_summary",
            "value":"Website",
            "hidden": true,
        },
        {
            "name":"customer_firstname",
            "value":"Demo"
        },
        {
            "name":"customer_lastname",
            "value":"User"
        },
        {
            "name":"customer_phone",
            "value":"0734567890"
        },
        {
            "name":"customer_email",
            "value":"demo"
        },
        {
            "name":"bill_firstname",
            "value":"Demo"
        },
        {
            "name":"bill_lastname",
            "value":"User"
        },
        {
            "name":"bill_address1",
            "value":"21"
        },
        {
            "name":"bill_city",
            "value":"Brisbane"
        },
        {
            "name":"bill_country",
            "value":"AU"
        },
        {
            "name":"bill_zip",
            "value":"4000"
        },
        {
            "name":"processurl",
            "value":`https://${latpayUrl}/api/hps?t=processurl`,
            "hidden": true,
            "append":"&r=~/process"
        },
        {
            "name":"cancelurl",
            "value":`https://${latpayUrl}/api/hps?t=cancelurl`,
            "hidden": true,
            "append":"&r=~/cancel"
        },
        {
            "name":"notifyurl",
            "value":`https://${latpayUrl}/api/hps?t=notifyurl`,
            "hidden": true,
            "append":"&r=~/notify"
        },
    ];

    const itemByName = (dataArray,name) => {
        const dx = dataArray.filter((d) => d.name===name);
        if (dx.length===0) return null;
        return dx[0];
    }

    const asAmount = (v) => {
        try {
            const a = parseFloat(v).toFixed(2);
            if (isNaN(a)) return "0.00";
            return a;
        } catch (e) {
            return "0.00";
        }
    }

    const hpsConcat = (d) => {
        const currency = itemByName(d,"currencydesc").value;
        const amount = itemByName(d,"amount").value;
        const reference = itemByName(d,"merchant_ref_number").value;
        const secret = itemByName(d,"secret").value;
        return `${currency.toUpperCase()}${asAmount(amount)}${reference}${secret}`;
    }

    /*const initialData = () => {
        const d = [...defaultData];
        const h =  hpsConcat(d);
        const tk = itemByName(d,"transactionkey");
        tk.value = "x";// sha1(h);
        return d;
    }*/

    const [data,setData] = useState(defaultData);
    const [hps,setHps] = useState(hpsConcat(defaultData));
    const [embedded,setEmbedded] = useState({...defaulEmbed,...account});
    const [initialised,setInit] = useState(true); 
    const [needsReload,setNeedsReload] = useState(false); 

    const handleLoad = () => { 
        loadjQuery(() => {
            loadLatpayJS(() => { 
                console.log("loading");
                // eslint-disable-next-line
                $("#latpay-element").empty();
                // eslint-disable-next-line
                LatpayCheckout.open({
                    merchantuserid: account.merchantuserid,
                    publickey: account.publickey,
                    status: (status) => {
                        console.log("status",status);
                        setNeedsReload(false);
                    }
                });
            });
        });
        handleChange({target: {name:"amount",value:"2.00"}});
    }

    useEffect(() => {
        handleLoad();
    }, []);

    const handleCheckout = (e) => {
        // eslint-disable-next-line
        LatpayCheckout.processpayment({
            amount: embedded.amount,
            currency: embedded.currency,
            reference: embedded.reference ,
            description: embedded.description,
            firstname: embedded.firstname,
            lastname: embedded.lastname,
            email: embedded.email,
            status: (token) => {
                console.log(token);
                if(token === "success"){
                    // Transtoken generated succesfully, you can proceed to form post after completing any custom work as necessary
                    // retrieve response data
                    const result = {
                        "amount": document.getElementById("amount").value,
                        "currency": document.getElementById("currency").value,
                        "description": document.getElementById("description").value,
                        "merchantRef": document.getElementById("reference").value,
                        "transactionkey": document.getElementById("TransToken").value
                    };
                    console.log(result);
                    //return true;
                }
                else {
                    // if transaction generation failed, merchant can complete any custom work and prevent form post
                    e.preventDefault();
                    //return false;
                }
            }
        });
            
    
    }

    const handleSubmit = (data) => {
        console.log("submit",data);
        //return true;
    }

    const onUpate = async (name,value) => {
        const dataCopy = [...data];
        const dx = dataCopy.filter((d) => d.name===name);
        if (dx.length===0) return;
        dx[0].value = value;
        const h = hpsConcat(dataCopy);
        const tk = itemByName(dataCopy,"transactionkey");
        tk.value = await sha1(h);
        setData(dataCopy);
        setHps(h);
    }

    const handleChange = (e) => {
        onUpate(e.target.name,e.target.value);
        setInit(true);
    }

    const handleEmbUpate = (name,value) => {
        const emb = {...embedded};
        emb[name] = value;
        setEmbedded(emb);
        if (accFields.indexOf(name)>=0) {
            //setNeedsReload(true);
            switch(name) {
                case "merchantuserid":
                    // eslint-disable-next-line
                    $("#merchantid").val(value)
                    break;
                case "publickey":
                    // eslint-disable-next-line
                    $("#publickey").val(value)
                    break;
            }
        }
    }

    const fieldValue = (f) => {
        if (!f.append)
            return f.value;
        if (`${f.value}`.toLowerCase().indexOf("latpay.net")<0)
            return f.value;
        const append = f.append.split("~").join(window.location.href);
        return `${f.value}${append}`;
    }

    const myServer = () => {
        if (embedded.myserver.indexOf("latpay.net")<0) return embedded.myserver;
        const hra = window.location.href.split("demo/hps")[0]
        if (embedded.myserver.indexOf("?")<0) 
            return `${embedded.myserver}?r=${hra}demo/hps/embedded`;
        return `${embedded.myserver}&r=${hra}demo/hps/embedded`;
    }

    return ( 
        <>
        <LatpayHeader />
        <Container className="py-5 text-start">
            <h2>Hosted Payment Page Demo</h2>
            <h4>HPS Redirect</h4>
            <p>The redirect version of HPS involves capturing all your cart/purchase information on your site and then performing a POST request to <b>https://lateralpayments.com/hps/hps_Payment.aspx</b>:</p>
            <p>This demo uses the test credentials, but you may also test with your own credentials.</p>
            <Row className="mb-3">
                <Col md={6}>
                    <FloatingLabel label="Merchant_User_Id">
                        <Form.Control name="Merchant_User_Id" value={itemByName(data,"Merchant_User_Id").value} onChange={handleChange}/>
                    </FloatingLabel>
                </Col>
                <Col md={6}>
                    <FloatingLabel label="Merchant_Secret">
                        <Form.Control name="secret"  value={itemByName(data,"secret").value} onChange={handleChange}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <p>
                The html form below is interactive, so click on any of the values highlighted in yellow to change the values. To test the form, click on the submit button below the form.
            </p>
            <Card className="p-3 mb-3 bg-dark text-light">
                <div>&lt;form method="post" action="https://lateralpayments.com/hps/hps_Payment.aspx"&gt;</div>
                {
                    data.filter(d => !d.exclude).map((f,x) => <div key={x} className="ps-4">
                        &lt;input name="<b>{f.name}"</b> value="{f.calculated? <b className="text-info">{f.value}</b>: <b className="text-warning" name="ed" contentEditable="true" onInput={(e)=>onUpate(f.name,e.target.innerText)}>{f.value}</b>}" {f.hidden&&`type="hidden"`} /&gt;
                    </div>)
                }
                <div className="ps-4">&lt;input type="submit" value="Submit"&gt;&lt;/input&gt;</div>
                <div>&lt;/form&gt;</div>
            </Card>
            {initialised ?
            <form method="post" action="https://lateralpayments.com/hps/hps_Payment.aspx"> 
                {
                    data.map((f,x) => <input key={x} type="hidden" name={`${f.name}`} value={`${fieldValue(f)}`} /> )
                }
                <div><input type="submit" value="Submit"></input> (by clicking this button the form will be processed and you'll be redirected to the payment page.)</div>
            </form>
            :<Alert variant="warning">Please enter a Merchant_User_Id and Merchant_Secret to test this form.</Alert>
            }
            <p className="mt-3">
                You'll notice that the <b>transactionkey</b> field is calculated as an SHA1 hash of the concatenated values of <b>currencydesc, amount, merchant_ref_number</b> and the <b>merchant_secret</b>, or in this case <b>{hps}</b>.
            </p>
            <p>The <b>merchant_secret</b> should NOT be accessible anywhere in your website front end code and should ideally be generated by your server code to keep secret.</p>

            <h4 className="mt-4">HPS Embedded</h4>
            <p>The embedded version of HPS allows you to keep the customer on your site, but you lose the ability to accept payments via Google Pay and Apple Pay:</p>
            <p>
                The html code snippet below is interactive, so click on any of the values highlighted in yellow to change the values. To test the form, click on the submit button below the form.
            </p>
            <Card className="p-3 mb-3 bg-dark text-light">
                <div>&lt;script&gt;</div>
                <div className="ps-4"><b>window.onload</b> = (e) {"=> {"}</div>
                <div className="ps-4">
                    <div className="ps-4">LatpayCheckout.open({"{"}</div>
                    <div className="ps-4">
                        {accFields.map((f) => 
                            <div className="ps-4" key={f}>
                                {f}: "<b className="text-warning" contentEditable="true" onInput={(e)=>handleEmbUpate(f,e.target.innerText)}>{embedded[f]}</b>",
                            </div>)
                        }
                        <div className="ps-4">status: (status) =&gt;{" {"} </div>
                        <div className="ps-4">
                            <div className="ps-4">console.log("status",status);</div>
                        </div>
                        <div className="ps-4">{"}"} </div>
                    </div>
                    <div className="ps-4">);                </div>
                </div>
                <div className="ps-4">{"}"}</div>
                <div>&nbsp;</div>
                <div className="ps-4">const <b>handleCheckout</b> = (e) {"=> {"}</div>
                <div className="ps-4">
                    <div className="ps-4">LatpayCheckout.processpayment({"{"}</div>
                    <div className="ps-4">
                        {embFields.map((f) => 
                            <div className="ps-4">
                                {f}: "<b className="text-warning" contentEditable="true" onInput={(e)=>handleEmbUpate(f,e.target.innerText)}>{embedded[f]}</b>",
                            </div>)
                        }
                        <div className="ps-4">status: (status) =&gt;{" {"} </div>
                        <div className="ps-4">
                            <div className="ps-4">if (status != "success") {"{"}</div>
                            <div className="ps-4">
                                <div className="ps-4">e.preventDefault();</div>
                            </div>
                            <div className="ps-4">{"}"} </div>
                        </div>
                        <div className="ps-4">{"}"} </div>
                    </div>
                    <div className="ps-4">);                </div>
                </div>
                <div className="ps-4">{"}"}</div>
                <div>&lt;/script&gt;</div>
                <div>&nbsp;</div>
                <div>&lt;form method="post" action="<b className="text-warning" contentEditable="true" onInput={(e)=>handleEmbUpate("myserver",e.target.innerText)}>{embedded["myserver"]}</b>"&gt;</div>
                    <div className="ps-4">&lt;div id="<b>latpay-element</b>"&gt;&lt;/div&gt;</div>
                    <div className="ps-4">&lt;button onClick={"{"}<b>handleCheckout</b>{"}"}&gt;Checkout&lt;/button&gt;</div>
                <div>&lt;/form&gt;</div>
            </Card>
            {/*<div><Button onClick={handleLoad}>Load</Button></div>*/}
            {needsReload ? <>
                <div>One or more of {accFields.join(", ")} has changed, please reload the form:</div>
                <div><Button onClick={handleLoad}>Reload</Button></div>
            </>:
            <Row>
                <Col md={8} lg={6} xl={4} className="position-relative">
                    <form method="post" action={myServer()}>
                        <div id="latpay-element">
                        </div>
                        <button id="customButton" onClick={handleCheckout}>Checkout</button>
                    </form>
                </Col>
            </Row>}
        </Container>
        <LatpayFooter/>
        <LatpayCopyright/>
        </>
     );
}
 
export default HPSDemo;