import { faDollyBox } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Col, FloatingLabel, Form, Image, Ratio, Row, Tab, Tabs } from "react-bootstrap";
import imgUtils from "../utils/imgutils";
import { faCircleCheck} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const People = ({list,onPersonChange}) => {

    const handleNext = () => {
        
    }

    const isComplete = (person) => {
        return (person.licenseno||"").length>0;
    }
    const tabCheck = (person) => {
        return isComplete(person) ? "text-success":"text-secondary opacity-25";
    }

    const nextBtn = (list) => {
        let ok = true;
        for (const l of list) {
            ok = ok && isComplete(l);
        }
        return <div className="d-grid mt-3">
            <Button variant={ok? "indigo" : "secondary"} onClick={handleNext}>{ok ? "Next" : "Please fill required fields"}</Button>
        </div>;

    }

    if (!list) return <></>;
    try {
    if (list.length===0) return <></>;
        return <>
        <h5>Identification details for the following {list.length>1?"people":"person"}:</h5>
        <Tabs
            defaultActiveKey="person0"
            id="people-tabs"
            className="mb-3"
        >
            {list.map((person,x) => 
            <Tab key={x} eventKey={`person${x}`} title={<span className="text-secondary">
                {person.name} <FontAwesomeIcon className={`fs-4 ms-3 ${tabCheck(person)}`} icon={faCircleCheck}/>
            </span>}>
                <PersonCard id={x} person={person} onChange={(p) => onPersonChange(x,p)}/>
            </Tab>
            )}
        </Tabs>
        {nextBtn(list)}
        </>;
    } catch (e) {
        console.log(e.message);
        return <></>;
    }
}

const PersonCard = ({person,id,onChange}) => {
    console.log("id",id);
    console.log("person",person);
    const handleChange = (e) => {
        person[e.target.name] = e.target.value;
        onChange(person);
    }
    const handleFileChange = async (e) => {
        const f = e.target.files[0];
        const b64 = await imgUtils.blobToBase64(f);
        person[e.target.name] = b64;
        console.log(b64);
        onChange(person);
    }

    const dob = () => {
        return  <>
            <FloatingLabel label="Date of Birth" className="mb-1">
                <Form.Control type="date" name={`dob`} value={person.dob||""} onChange={handleChange} />
            </FloatingLabel>  
        </>;
    }

    const address = () => {
        return  <>
            <FloatingLabel label="Street Address" className="mb-1">
                <Form.Control name={`street`} value={person.street||""} onChange={handleChange} />
            </FloatingLabel>  
            <Row className="mb-1">
                <Col>
                    <FloatingLabel label="Suburb">
                        <Form.Control name={`suburb`} value={person.suburb||""} onChange={handleChange} />
                    </FloatingLabel>  
                </Col>
                <Col>
                    <FloatingLabel label="Postcode">
                        <Form.Control name={`postCode`} value={person.postCode||""} onChange={handleChange} />
                    </FloatingLabel>  
                </Col>
            </Row>
            
        </>;
    }

    const licence = () => {
        return  <>
        <FloatingLabel label="Drivers License Number" className="mb-1">
            <Form.Control name={`licenseno`} value={person.licenseno||""} onChange={handleChange} />
        </FloatingLabel>  
        <Row className="mb-1">
            <Col>
                <FloatingLabel label="State of Issue">
                    <Form.Control name={`licensestate`} value={person.licensestate||""} onChange={handleChange} />
                </FloatingLabel>  
            </Col>
            <Col>
                <FloatingLabel label="Drivers License Expiry">
                    <Form.Control name={`licenseexp`} value={person.licenseexp||""} onChange={handleChange} />
                </FloatingLabel>  
            </Col>
        </Row>
        <Row className="mb-1">
            <Col>
                <FloatingLabel label="Drivers License Front">
                    <Form.Control name="dlfront" type="file" onChange={handleFileChange}/>
                </FloatingLabel>  
                <Ratio aspectRatio="16x9">
                    <div className="licenceImg" style={{backgroundImage: `url(${person.dlfront})`}}>
                    </div>
                </Ratio>
            </Col>
            <Col>
                <FloatingLabel label="Drivers License Back">
                    <Form.Control name="dlback" type="file" onChange={handleFileChange}/>
                </FloatingLabel>  
                <Ratio aspectRatio="16x9">
                    <div className="licenceImg" style={{backgroundImage: `url(${person.dlback})`}}>
                    </div>
                </Ratio>
            </Col>
        </Row>
        </>;
    }

    const reqSection = (req) => {
        switch (req) {
            case "address":
                return address();
            case "licence":
                return licence();
            case "dob":
                return dob();
            default:
                return <Alert variant="danger">{req}</Alert>;
        }
    }

    return <>
        {person.required.map((req) => <div key={req}>{reqSection(req)}</div>)}
    </>
    /*return <div>
        <FloatingLabel label="Email Address" className="mb-1">
            <Form.Control name={`email`} value={person.name||""} onChange={handleChange} />
        </FloatingLabel>    
    </div>;*/
        
}

export default People;