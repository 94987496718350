import { Container } from "react-bootstrap";
import LatpayFooter from "../layout/footer";
import LatpayCopyright from "../layout/copyright";
import LatpayHeader from "../layout/header";

const AllIneOneDemo = () => {
    return ( 
        <>
        <LatpayHeader />
        <Container className="py-5">
            <h2>All-in-one Demo</h2>
            <p>Stay tuned!</p>
        </Container>
        <LatpayFooter/>
        <LatpayCopyright/>
        </>
     );
}
 
export default AllIneOneDemo;