import { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Col, Container, Form, Image, Navbar, Row, Spinner, Toast} from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DB from "../services/firestoredb";
import LPAPI from "../services/paylink";
import LatPayBrand from "./brand";
import { messaging, getPushToken, onMessageListener } from "../firebase-config";

const POS = () => {
    const navigate = useNavigate();
    const  params = useParams();
    const merchantId = params.client || "none";
    const [searchParams] = useSearchParams();
    const [merchInfo,setMerchInfo] = useState();
    const [viewing,setViewing] = useState(false);
    const [proceeding,setProceeding] = useState(false);
    const [invalid,setInvalid] = useState(false);
    const [invalidTrx,setInvalidTrx] = useState(false);
    const [brand,setBrand] = useState();
    const [order,setOrder] = useState({
      amount: 0,
      items: {
      }
    });
    const [pushToken, setPushToken] = useState(false);
  

    // lookup client
    // if no trxId, show all products
    // if trxId just show pay options
    
    useEffect(() => {
        const getInfo = async() => {
            const info = await DB.getPOSInfo(merchantId);
            setInvalid(!info);
            if (info) {
              setBrand(LatPayBrand.merchantBrand(info));
              // check each category to make sure there are items in the category!
              const cats = [...info.categories];
              console.log(cats);
              for (const c of cats) {
                const l = info.items.filter((v) => v.cat===c && v.available);
                if (l.length===0) {
                  const x = info.categories.indexOf(c);
                  console.log(x);
                  info.categories.splice(x,1);
                }
              }
              console.log(info);
              setMerchInfo(info);
            }
        }
        getInfo();
        getPushToken(setPushToken);
      }, [merchantId]);

    const table = searchParams.get("t");
    const name = searchParams.get("n");

    const proceed = async () => {
      setProceeding(true);
      const co = [];
      for(const name in order.items) {
        const l = merchInfo.items.filter((v) => v.name===name);
        const info = (l.length===0)?{}:l[0];
        co.push({name:name,price:info.price,order:order.items[name],info:info});
      }
      const result = await LPAPI.createTransaction(merchantId,{
        order: co,
        amount: order.amount,
        name: order.name||name,
        table: table,
        description: "Food Order",
        source:"diy-pos",
        trxRef: "",
        pushToken: pushToken,
      });

      if (result.trxId) {
        navigate(`/pos/${merchantId}/${result.trxId}`);
      } else {
        setProceeding(false);
      }
    }

    const toggleViewing = (e) => {
      if(order.amount>0) {
        setViewing(!viewing);
      }
    }

    const itemQty = (name) => {
      const item = order.items[name];
      return (item && item.qty) || 0;
    }

    const adjustQty = (name,add) => {
      const l = merchInfo.items.filter((v) => v.name===name);
      if (l.length===0) return;
      const posItem = l[0];
      const orderItem = {...(order.items[name]||{qty:0})};
      const o = {...order};
      o.amount -= orderItem.qty*(posItem.price||0);
      orderItem.qty += add;
      if (orderItem.qty<0) orderItem.qty = 0;
      o.amount += orderItem.qty*(posItem.price||0);
      o.items[name] = orderItem;
      setOrder(o);
    }

    const handleChange = (e) => {
      const o = {...order};
      o[e.target.name] = e.target.value;
      setOrder(o);
  }


    const showOrder = () => {

        if (proceeding) {
          return <div className="text-center my-5 py-5">
            <Spinner animation="border"/>
            <div className="my-2">Preparing Payment</div>
            <div className="my-2">${order.amount.toFixed(2)}</div>
          </div>
        }

        const co = [];
        for(const name in order.items) {
          if(itemQty(name)>0) {
            const l = merchInfo.items.filter((v) => v.name===name);
            const info = (l.length===0)?{}:l[0];
            co.push({name:name,price:info.price,order:order.items[name],info:info});
          }
        }
        return <>
          <h4>Order Details</h4>
          {
            co.map((item,x) => <div key={x} className="d-flex mb-2">
              <div className="flex-fill">
                <div>{item.name}</div>
                <div>${item.price.toFixed(2)}</div>
              </div>
              <div className="">
                <ButtonGroup>
                  <Button size="sm" variant="secondary" onClick={() => adjustQty(item.name,-1)}>-</Button>
                  <Button size="sm" variant={itemQty(item.name)===0 ? "light" : "warning"} disabled className="monoSpace fs-6">{itemQty(item.name)}</Button>
                  <Button size="sm" variant="secondary" onClick={() => adjustQty(item.name,+1)}>+</Button>
                </ButtonGroup>
              </div>
            </div>)
          }
          <hr/>
          <Row>
            <Col className="text-start pt-2">
              Order Total
            </Col>
            <Col className="text-end">
              <span className="fs-4 me-1">$</span><b className="fs-1">{order.amount.toFixed(2)}</b>
            </Col>
          </Row>
          <hr/>
          {table ? 
            <div className="text-center fs-3 mb-3">Table {table}</div> 
          : 
            <div className="text-center mb-3">
              <div>Please provide a name for the order</div>
              <div className="mt-2 px-5"><Form.Control className="text-center fs-3" type="text" placeholder="name" name={`name`} value={order.name||name||""} onChange={handleChange} /></div>
            </div>
          }
          <Row> 
            <Col><Button variant="secondary" className="w-100" onClick={toggleViewing}>Add more items</Button></Col>
            <Col>
            {merchInfo.live ?
              <Button variant="dark" className="w-100" onClick={proceed}>Proceed to Payment</Button>
              :
              <Button variant="warning" className="w-100" onClick={proceed}>Test Payment</Button>
            }
            </Col>
          </Row>
        </>
    }

    const originalItemView = (item) => {
      return <Card className="mb-2">
        <h5 className="card-img-top bg-secondary text-light p-1"><div className="float-end">${item.price.toFixed(2)}</div>{item.name}</h5>
        <div className="d-flex">
          <div className="flex-fill p-2">{item.description}</div>
          <div className="p-2">
            <ButtonGroup>
              <Button size="sm" variant="secondary" onClick={() => adjustQty(item.name,-1)}>-</Button>
              <Button size="sm" variant={itemQty(item.name)===0 ? "light" : "warning"} disabled className="monoSpace fs-6">{itemQty(item.name)}</Button>
              <Button size="sm" variant="secondary" onClick={() => adjustQty(item.name,+1)}>+</Button>
            </ButtonGroup>
          </div>
        </div>
        
      </Card>;
    }

    const imgItemView = (item) => {
      return <Row className="align-items-center mb-1" style={{ height: "30vw", overflow: "hidden", backgroundColor:"#F0F0F0"}}>
      <Col xs={4} className="p-0">
        <div style={{height:"30vw", backgroundColor: "#E0E0E0", backgroundImage:`url(/images/fnb.png)`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center"}}>
          <div style={{height:"30vw", backgroundImage:`url(${item.image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center"}}></div>
        </div>
      </Col>
      <Col xs={8} className="px-2 py-0 d-flex flex-column" style={{height:"30vw"}}>
          <div><b>{item.name}</b></div>
          <div className="d-flex flex-grow-1">{item.description}</div>
          <div className="py-1 d-flex flex-row">
            <b className="d-flex flex-grow-1">${item.price.toFixed(2)}</b>
            
            <ButtonGroup>
              <Button size="sm" variant="secondary" onClick={() => adjustQty(item.name,-1)}>-</Button>
              <Button size="sm" variant={itemQty(item.name)===0 ? "light" : "warning"} disabled className="monoSpace fs-6">{itemQty(item.name)}</Button>
              <Button size="sm" variant="secondary" onClick={() => adjustQty(item.name,+1)}>+</Button>
            </ButtonGroup>
          </div>
      </Col>
    </Row>;
    }

    const showPos = () => {
      
      return merchInfo.categories.map((cat,x) => <div key={x}>
                    <h4>{cat}</h4>
                    {merchInfo.items.filter((v) => v.cat===cat && v.available).map((item,i) => <div key={i}>
                      {imgItemView(item)}
                    </div>)}
                  </div>);
                
    }

    if (invalid) { return LatPayBrand.invalidMerchant(); }
    if (!brand) { return LatPayBrand.loadScreen(); }
  
    return (<>
    
      <Navbar className="py-0" style={{backgroundColor:brand.bannerBg}}>
        <Container className="d-flex p-2">
          <div className="flex-fill paytop" style={{
                    backgroundImage: `url(data:image/png;charset=utf-8;base64,${brand.logo})`,
                }}>{!merchInfo.live && <Badge bg="warning" className="mt-2" style={{fontSize: "large"}}>TEST MODE</Badge>}</div>
        </Container>
      </Navbar>
        <Container className="payContainer py-1 text-left">
            {merchInfo ? (viewing ? showOrder() : showPos()) : <Spinner animation="border"/>}
        </Container>

        <div className="fixed-bottom">
          {merchInfo && !viewing && <>
            <div style={{backgroundColor:brand.bannerBg,color:brand.bannerText}}>
              <Container className="pt-1 clickable" onClick={toggleViewing}>
                <Row>
                  <Col className="text-start">
                    {table && <div>Table {table}</div>}
                  </Col>
                  <Col>{order.amount>0 && <Badge bg="dark" className="mt-2 p-2" style={{fontSize: "larger"}}><b>View<br/>Order</b></Badge>}</Col>
                  <Col className="text-end">
                    <div style={{fontSize:"10px"}}>Order Total {!merchInfo.live && <Badge bg="warning" style={{fontSize: "larger"}}>TEST MODE</Badge>}</div>
                    <div><span className="fs-4 me-1">$</span><b className="fs-1">{order.amount.toFixed(2)}</b></div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>}
          <div className="justify-content-center topHeader py-0 text-muted">
              Powered by <img src="/images/lat-pay-logo-300x135.png" className="PayFooter-logo" alt="logo" />
          </div>
        </div>
        </>
     );

}
 
export default POS;