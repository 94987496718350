import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './App.css';
import BridgeDemo from "./demos/bridge";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AllIneOneDemo from "./demos/allinone";
import HPSDemo from "./demos/hps";
import Home from "./pages/home";
import ApplicationForm from "./forms/application";
import TestAccountForm from "./forms/testaccount";
import WordPressAccountForm from "./forms/wordpresstest";
import Compliance from "./compliance/comphome";
import POS from "./pos/poshome";
import POSPay from "./pos/pay";
import KeyGenDemo from "./demos/keygen";
import ShortCode from "./latpay/shortcodes";
import QRPOS from "./demos/qrdemo";
import Search3CX from "./crm/search3cx";
import WebSocket from "./crm/websocket";
import POSAdmin from "./pos/admin";
import { onMessageListener } from "./firebase-config";
import { Toast } from "react-bootstrap";

function App() {

  /*const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
  }).catch(err => console.log('failed: ', err));*/

  return (
    <div className="App">
      {/*<Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
          position: 'absolute',
          zIndex:10000,
          top: 20,
          right: 20,
        }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Notification</strong>
            <small>12 mins ago</small>
          </Toast.Header>
          <Toast.Body>There are some new updates that you might love!</Toast.Body>
      </Toast>*/}
        
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search3CX />} />
          <Route path="/crm/ws" element={<WebSocket />} />
          <Route path="/demo/bridge" element={<BridgeDemo />} />
          <Route path="/demo/hps/:id" element={<HPSDemo />} />
          <Route path="/demo/hps" element={<HPSDemo />} />
          <Route path="/demo/qr" element={<QRPOS />} />
          <Route path="/demo/keygen" element={<KeyGenDemo />} />
          <Route path="/demo" element={<AllIneOneDemo />} />
          <Route path="/form/:id/:step" element={<ApplicationForm />} />
          <Route path="/form/:id" element={<ApplicationForm />} />
          <Route path="/s/:code" element={<ShortCode />} />
          <Route path="/compliance/:id" element={<Compliance />} />
          <Route path="/merchant/admin" element={<POSAdmin />} />
          <Route path="/pos/:client/:id" element={<POSPay />} />
          <Route path="/pos/:client" element={<POS />} />
          <Route path="/:id" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>

        </Router>
    </div>
  );
}

export default App;
