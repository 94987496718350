import latpayDomain from "../config";

const LPAPI = {
    createTransaction: async (merchantId,trx) => {
        
        trx.merchantId = merchantId;

        var json = JSON.stringify(trx);

        try {
            const response = await fetch(
              `https://${latpayDomain}/api/pos`,
              {
                method: 'POST',
                body: json,
                //mode: 'cors',
                headers: { 'Content-Type': 'application/json',}
              }
            );
            const result = await response.json();
            //console.log(result);
            return result;
        } catch (e) {
            console.log(e.message);
            return {"error":e.message };
        }        
    },
    createMerchant: async (data) => {
        
        var json = JSON.stringify(data);

        try {
            const response = await fetch(
              `https://${latpayDomain}/api/create`,
              {
                method: 'POST',
                body: json,
                //mode: 'cors',
                headers: { 'Content-Type': 'application/json',}
              }
            );
            const result = await response.json();
            //console.log(result);
            return result;
        } catch (e) {
            console.log(e.message);
            return {"error":e.message };
        }        
    },
    processCard: async (merchantId,trxId,cardInfo, testMode, trxCurrency) => {
        const trx = {
            //"amount": 10.00,
            //"firstName": "Tom",
            //"lastName": "Jones",
            //"ref":"purchaseRef",
            //"description":"Purchase",

            "merchantId": merchantId,
            "trxId": trxId,
            "currency": trxCurrency,
            "cardholder": cardInfo.name,
            "pan": cardInfo.card,
            "exp": cardInfo.exp,
            "cvc": cardInfo.cvc,
            "testMode": testMode,
            /*"fees": {
            "processingfee": "0.25",
            "surcharge": "0.25"
            }*/
        }

        const json = JSON.stringify(trx);

        try {
            const response = await fetch(
              `https://${latpayDomain}/api/pay`,
              //`http://localhost:5000/latpay-crm/us-central1/processTrx`,
              {
                method: 'POST',
                body: json,
                //mode: 'cors',
                headers: { 'Content-Type': 'application/json',}
              }
            );
            const result = await response.json();
            //console.log(result);
            return result;
        } catch (e) {
            console.log(e.message);
            return {"error":e.message };
        }        
    },
    processGooglePay: async (requestData) => {
      requestData.wallet = "gpay";
        const json = JSON.stringify(requestData);
        //const url = 'https://us-central1-latpay-crm.cloudfunctions.net/processWallet';
        const url = `https://${latpayDomain}/api/wallet`;
        //console.log(`Googlepay url: ${url}`);
        //const url = `https://www.lateralpayments.com/checkout-staging/wallet/gpayprocess`;
        try {
            const response = await fetch(
              url,
              {
                method: 'POST',
                body: json,
                //mode: 'no-cors',
                headers: { 'Content-Type': 'application/json',}
              }
            );
            const result = await response.json();
            if (result.status.statuscode !== "0") {
                console.log(result.status.errordesc);
            }
            return result;
        } catch (e) {
            console.log(e.message);
            return {"error":e.message };
        }        
    },
}

export default LPAPI;