const scrollToTop = (delay) => {
    delay = delay || 0;
    setTimeout(() => window.scrollTo({top:0, behavior: "smooth"}),delay);
}

const asAmount = (amount,currency) => {
    const v= new Intl.NumberFormat('en-AU', 
        { style: 'currency', currency: currency|| 'AUD' }
    ).format(amount);
    return v.split("$").join("");
}

export default {scrollToTop, asAmount} ;