import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import LatpayFooter from "../layout/footer";
import LatpayCopyright from "../layout/copyright";
import LatpayHeader from "../layout/header";
import { useState } from "react";
//import { sha256 } from "js-sha256";
import {sha1,sha256,sha384,sha512} from 'crypto-hash';
import { useEffect } from "react";

const KeyGenDemo = () => {
    const initValues = {
        currency: "GBP",
        amount: "",
        merchantRef: "Trx12345",
        merchantSecret: "SALT",
        terminalSecret: "testlatpaysec",
        deviceID: "01",
    };

    const asAmount = (v) => {
        try {
            const a = parseFloat(v).toFixed(2);
            if (isNaN(a)) return "0.00";
            return a;
        } catch (e) {
            return "0.00";
        }
    }

    const hps = (k) => {
        return `${k.currency.toUpperCase()}${asAmount(k.amount)}${k.merchantRef}${k.merchantSecret}`
    }

    const api = (k) => {
        return `${k.currency.toUpperCase()}${asAmount(k.amount)}${k.merchantRef}${k.merchantSecret}`
    }

    const [keys,setKeys] = useState(
        {
            ...initValues,
            hpstoHash: hps(initValues),
            apitoHash: api(initValues),
        }
    );

    const calcHash = async (k) => {
        k.hash256 = k.v256 ? await sha256(k.v256) : "";
        k.hash1 = k.v1 ? await sha1 (k.v1) : "";
        k.hpstoHash = hps(k);
        k.apitoHash = api(k);
        k.hashhps = k.hpstoHash ? await sha1 (k.hpstoHash) : "";
        k.hashapi = k.apitoHash ? await sha256 (k.apitoHash) : "";
    }

    const handleChange = async (e) => {
        const k = {...keys};
        k[e.target.name] = e.target.value;
        await calcHash(k);
        setKeys(k);
    }

    return ( 
        <>
        <LatpayHeader />
        <Container className="py-5 text-start">
            <h2 className="mt-2">Key Generator Demo</h2>
            <h3>Sha-256 Hash</h3>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-2">
                        <Form.Label>Value to hash</Form.Label>
                        <Form.Control as="textarea" rows={2} name="v256" value={keys.v256||""} onChange={handleChange}></Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6}>
                <Form.Group className="mb-2">
                        <Form.Label>SHA-256 Hash</Form.Label>
                        <Form.Control as="textarea" rows={2}  readOnly value={keys.hash256||""} onChange={handleChange}></Form.Control>
                        </Form.Group>
                </Col>
            </Row>
            <h3>Sha-1 Hash</h3>
            <Row className="mb-2">
                <Col md={6}>
                    <Form.Group className="mb-2">
                        <Form.Label>Value to hash</Form.Label>
                        <Form.Control as="textarea" rows={2} name="v1" value={keys.v1||""} onChange={handleChange}></Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6}>
                <Form.Group className="mb-2">
                        <Form.Label>SHA-1 Hash</Form.Label>
                        <Form.Control as="textarea" rows={2}  readOnly value={keys.hash1||""} onChange={handleChange}></Form.Control>
                        </Form.Group>
                </Col>
            </Row>
            <h3>HPS Transaction Key Generator</h3>
            <Row className="mb-2">
                <Col md={6} className="mb-2">
                     <FloatingLabel label="Currency" className="mb-1">
                        <Form.Control name={`currency`} value={keys.currency||""} onChange={handleChange} />
                    </FloatingLabel>  
                     <FloatingLabel label="Amount" className="mb-1">
                        <Form.Control name={`amount`} value={keys.amount||""} onChange={handleChange} />
                    </FloatingLabel>  
                     <FloatingLabel label="Merchant Reference" className="mb-1">
                        <Form.Control name={`merchantRef`} value={keys.merchantRef||""} onChange={handleChange} />
                    </FloatingLabel>  
                     <FloatingLabel label="Merchant Secret" className="mb-1">
                        <Form.Control name={`merchantSecret`} value={keys.merchantSecret||""} onChange={handleChange} />
                    </FloatingLabel>  
                </Col>
                <Col md={6} className="mb-2">
                <Form.Group  className="mb-2">
                        <Form.Label>Value to hash</Form.Label>
                        <Form.Control as="textarea" rows={2}  readOnly value={keys.hpstoHash||""} onChange={handleChange}></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2">
                        <Form.Label>Transaction Key (SHA-1 Hash)</Form.Label>
                        <Form.Control as="textarea" rows={2}  readOnly value={keys.hashhps||""} onChange={handleChange}></Form.Control>
                </Form.Group>
                </Col>
            </Row>
            <h3>API Merchant Key Generator</h3>
            <Row className="mb-2">
                <Col md={6} className="mb-2">
                <FloatingLabel label="Amount" className="mb-1">
                        <Form.Control name={`amount`} value={keys.amount||""} onChange={handleChange} />
                    </FloatingLabel>  
                     <FloatingLabel label="Currency" className="mb-1">
                        <Form.Control name={`currency`} value={keys.currency||""} onChange={handleChange} />
                    </FloatingLabel>  
                     <FloatingLabel label="Device Id" className="mb-1">
                        <Form.Control name={`deviceID`} value={keys.deviceID||""} onChange={handleChange} />
                    </FloatingLabel>  
                     <FloatingLabel label="Terminal Secret" className="mb-1">
                        <Form.Control name={`terminalSecret`} value={keys.terminalSecret||""} onChange={handleChange} />
                    </FloatingLabel>  
                </Col>
                <Col md={6} className="mb-2">
                <Form.Group  className="mb-2">
                        <Form.Label>Value to hash</Form.Label>
                        <Form.Control as="textarea" rows={2}  readOnly value={keys.apitoHash||""} onChange={handleChange}></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2">
                        <Form.Label>Transaction Key (SHA-1 Hash)</Form.Label>
                        <Form.Control as="textarea" rows={2}  readOnly value={keys.hashapi||""} onChange={handleChange}></Form.Control>
                </Form.Group>
                </Col>
            </Row>
        </Container>
        <LatpayFooter/>
        <LatpayCopyright/>
        </>
     );
}
 
export default KeyGenDemo;