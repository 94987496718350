import { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";

const DDRSA = ({info,handleSubmit}) => {

    const [ok,setOK] = useState(false);

    const handleCheck = (e) => {
        console.log(e.target);
        setOK(e.target.checked);
    }

    const handleNext = () => {
    }

    try {
        return <>
            <h4>Direct Debit Request Service Agreement</h4>
            <div style={{fontSize: "smaller"}}>
            <p>Where your Net Daily Processing or your Latpay account is negative, or Latpay have requested you to fund your account for any reason associated with the Latpay Acquiring and Payment Services Agreement, you authorise Latpay to debit your registered Bank Account by using the Bulk Electronic Clearing Systems (BECS).</p>
            {/*<p>On behalf of <b>{info.name}</b>, I ({info.signedBy}) authorise Lateral Payment Solutions Pty Ltd trading as Latpay under User ID 613606 to debit my bank account in the event of a negative balance on my Latpay account as detailed above.</p>*/}
            <p>Transfers to and from your Bank Account will come from Latpay and the timing of the transfers will be according to your Payment Schedule. You can view details on your Daily Statement via Merchant Online Reporting in advance of any transfers (debits and credits).</p>
            <p>
            It is your responsibility to:
            <ol>
                <li>Check and confirm that your Bank Account can accept debit and credit transfers through BECS.</li>
                <li>Check your bank statement account details and to verify that all amounts are correct.</li>
                <li>Ensure that your account has sufficient funds to allow for a debit transfer.</li>
            </ol>
            </p>
            <p>If transfers are returned to Latpay by your bank, we may charge you a fee of $10.00 per BECS decline in addition to any fees your bank may charge. If a debit transfer is returned to Latpay because your Bank Account has insufficient funds, it is your responsibility to fund the Bank Account so that we can re-process the debit transfer.</p>
            <p>If you believe there has been an error in debiting your account, you should notify us immediately neel operations@latpay.com. We also recommend you notify your bank. If there has been an error, we’ll arrange with your bank to adjust your account and we’ll notify you of the amount of the adjustment. If we don’t find an error, we’ll respond to your query with evidence of the correctness of the transfer.</p>
            <p>Latpay will provide you with at least 14 days’ notice before any changes to this Direct Debit Request Service Agreement. If you’d like to amend this Direct Debit Request Service Agreement or to request that a transfer be deferred or altered, you can do so by emailing us, and we will make the change within 5 business days. You can also request that a transfer be stopped, cancelled, or deferred through your bank.</p>
            <p>Information relating to this Direct Debit Request Service Agreement is treated according to Latpay’s <a rel="noopener noreferrer" target="_blank" href="https://latpay.com/privacy-policy/">Privacy Policy</a></p>
            </div>
            {/*<h4>Direct Debit Authority</h4>
            <Row>
                <Col>
                    <FloatingLabel label="Authorised By:">
                        <Form.Control name={`signedBy`} value={info.signedBy||""} onChange={handleChange} />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="Date:">
                        <Form.Control name={`signedBy`} value={info.signedBy||""} onChange={handleChange} />
                    </FloatingLabel>
                </Col>
            </Row>*/}
            <Row className="mb-4">
                <Col xs={1}>
                    <Form.Group className="py-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" defaultChecked={ok||false} onChange={handleCheck}/> 
                    </Form.Group>
                </Col>
                <Col xs={11} style={{fontSize: "smaller"}}>
                On behalf of <b>{info.name}</b>, I (<b>{info.signedBy}</b>) authorise Lateral Payment Solutions Pty Ltd trading as Latpay under User ID 613606 to debit my bank account in the event of a negative balance on my Latpay account as detailed above.
                </Col>
            </Row>
            <div className="d-grid mb-3">
                <Button variant={ok? "indigo" : "secondary"} onClick={handleSubmit}>{ok ? "Next" : "Please tick the box to acknowledge"}</Button>
            </div>
            
        </>;
    } catch (e) {
        return <></>;
    }
}

export default DDRSA;