import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import DB from "../services/firestoredb";
import imgUtils from "../utils/imgutils";
import LatpayFooter from "../layout/footer";
import LatpayCopyright from "../layout/copyright";
import LatpayHeader from "../layout/header";

const Home = () => {
    const { id } = useParams();
    const [slides,setSlides] = useState();
    const [img,setImg] = useState();

    useEffect(() => {
        const getSlides = async() => {
            const navData = await DB.getNav();
            setSlides(navData.slides);
        }
        getSlides();

    }, []);

    const convertBlobToBase64 = async (blob) => { // blob data
        console.log("blob",blob);
        const b64 = await imgUtils.blobToBase64(blob);
        console.log("b64",b64);
        setImg(b64);

        const doc =  {
            "id": "upload_test",
            "content_id": "",
            "type": `${blob.type}`,
            //"disposition": `${a["disposition"]}`,
            "fileName": `${blob.name}`,
            "size": blob.size,
            "source": "upload",
            "sourceId": "",
            "providedBy":"merchant",
            "merchants":["PqoAAWQIFJ0QXqPyAn7F"],
            "date": new Date().toISOString(),
          };
          var file = b64.split(",")[1].trim();
          await DB.saveDoc(doc,file);

        return b64;
      }
      
      

    const handleFileChange = (e) => {
        const f = e.target.files[0];
        console.log(f);
        //console.log(URL.createObjectURL(e.target.files[0]));
        convertBlobToBase64(f);
        //setImg(URL.createObjectURL(e.target.files[0]));
    }

    return ( 
        <>
        <LatpayHeader />
        {slides && <Carousel infiniteLoop showThumbs={false} autoPlay interval={5000}>
            {slides.map((s,x) => 
                <div key={x} style={{backgroundImage: `url(images/slide${x+1}.jpg)`}}>
                    <Container>
                        <Row>
                            {(x===1 || x === 4) && <Col></Col>}
                            <Col className="text-left p-5">
                                <div className="fs-1">{s.title}</div>
                                <div className="fs-4">{s.subTitle}</div>
                            </Col>
                            {!(x===1 || x === 4) && <Col></Col>}
                        </Row>
                    </Container>
                </div>
            )}
            </Carousel>}
        <Container className="py-5 text-left">
            <h2>Welcome to Latpay</h2>
            <p>Stay tuned! {id}</p>
            <p>
                <img alt="" src={img}/>
            </p>
            {/*<p>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Default file input example</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange}/>
                </Form.Group>
            </p>*/}
        </Container>
        <LatpayFooter/>
        <LatpayCopyright/>
        </>
     );
}
 
export default Home;