const loadLatpayJS = (callback) => {
    const existingScript = document.getElementById('latpayJS');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://lateralpayments.com/checkout/scripts/latpay2.js';
      script.id = 'latpayJS';
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };
  export default loadLatpayJS;