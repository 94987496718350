import { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormList from "../forms/formlist";
import DB from "../services/firestoredb";
import { auth } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";

const PayHeader = () => {

  const [nav,setNav] = useState([]);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const getNav = async() => {
        const navData = await DB.getNav();
        setNav(navData.main);
    }
    getNav();

  }, []);

  const handleLogout = () => {
    DB.signOut();
  }

    return (
      <Navbar variant="light" expand="xs" className="topHeader py-0">
        <Container>
            <Navbar.Brand><Link to="/"><img src="/images/lat-pay-logo-300x135.png" className="Pay-logo" alt="logo" /></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link to="/" as={Link}>Home</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar> 
  );
}
 
export default PayHeader;