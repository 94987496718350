import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import LatpayBridge from "../latpay/latpaybridge";
import LatpayFooter from "../layout/footer";
import LatpayCopyright from "../layout/copyright";
import LatpayHeader from "../layout/header";

const r = {
  "deviceStatus":{
    "state":"2",
    "statusdesc":"Card Read",
    "statuscode":"0",
    "errorcode":"00",
    "errordesc":""
  },
  "paymentresult":{
    "accountid":"610059701",
    "storeid":"001",
    "deviceid":"01",
    "transactionid":"1384484",
    "responsekey":"",
    "customerid":"24701",
    "transactiondate_utc":"26/09/2022 00:36:00",
    "consumer":{
      "firstname":"Over%20The",
      "lastname":"Counter",
      "phone":"0404044044",
      "email":"overthecounter@merchant.com"
    },
    "order":{
      "orderid":"1384473",
      "reference":"1664152570478",
      "currency":"AUD",
      "amount":"1.00",
      "purchasesummary":"Over%20the%20counter%20purchase"
    },
    "billing":{
      "type":"card",
      "card":{
        "cvc":null,
        "token":"0x8a1429c96cc5da9b015e771d7fe768d0c11315a3"
      }
    },
    "status":{
      "responsetype":"0",
      "statuscode":"1",
      "errorcode":"1001",
      "errordesc":"Prescreen failed"
    }
  },
  "message":"Prescreen failed",
  "status":"Prescreen failed"
};

const BridgeDemo = () => {

  const [processing, setProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [amount, setAmount] = useState("5.00");
  const [message, setMessage] = useState();
  const [trxId, setTrxId] = useState();

  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }

  const handleProcess = async () => {
    var trxId = `${(new Date()).getTime()}`;
    console.log(trxId);
    setTrxId(trxId);
    setProcessing(true);
    setMessage("Connecting");
    const initStatus = await LatpayBridge.init();
    console.log(initStatus);
    const deviceReady = initStatus.connected;
    if (!deviceReady) {
      setMessage("No device connected");
      await timeout(2000);
      setProcessing(false);
      return;
    }
    const trx = {
      firstName: "Over The",
      lastName: "Counter",
      phone: "0404044044",
      email: "overthecounter@merchant.com",
      ref: trxId,
      currency: "AUD",
      amount: amount,
      summary: "Over the counter purchase",
    };
    const payResult = await LatpayBridge.pay(trx);
    /*console.log(payResult);
    if (payResult.message==="Busy") {
      setMessage(payResult.message);
      await timeout(5000);
      setProcessing(false);
      return;
    }*/
    setMessage("Connecting");
    var trxStatus = {};
    var waiting = true
    while(waiting) {
      await timeout(500);
      trxStatus = await LatpayBridge.status();
      setMessage(trxStatus.status);
      switch (trxStatus.status) {
        case "ready":
        case "processing":
        case "connecting":
            break;
        case "declined":
        case "timeout":
        case "approved":
          waiting = false;
          break;
        default:
          waiting = false;
          break;
      }
    };
    setCompleted(true);
    //setMessage(trxStatus.message);
    //await timeout(5000);
    //await LatpayBridge.complete(trxId);
    //setProcessing(false);
  }

  const handleCancel = async () => {
    setProcessing(false);
    await LatpayBridge.cancel(trxId);
  }

  const handleNext = async () => {
    setCompleted(false);
    setProcessing(false);
  }

  const handleChange = (e) => {
    setAmount(e.target.value);
  }

    return ( 
      <>
      <LatpayHeader />
    <Container className="py-5">
                <Row>
                    <Col></Col>
                    <Col sm={12} md={8} lg={6} xl={4}>
            <h2>Terminal Bridge Demo</h2>
            {processing ? <>
          <h4 className="p-5">Processing {amount}</h4>
          {message && <Alert variant="warning"> <h2>{message}</h2></Alert>}
          {completed ? 
          <p>
          <Button variant="primary" onClick={handleNext}>Next</Button>
          </p>
          :
         <p>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
            </p>
          }
          </>:
        <>

            <div className="my-5 text-left">
            <Form>
            <Form.Label>Enter amount to process</Form.Label>
                <Form.Control className="inputStyle" name="amount" placeholder='Amount' value={amount} onChange={handleChange} />
              </Form>
              </div>
              <p>
                <Button variant="secondary" size="lg" onClick={handleProcess}>Process Payment</Button>
          </p>
          </>
        }
        </Col>
        <Col></Col>
          </Row>
        </Container> 
        <LatpayFooter/>
        <LatpayCopyright/>
        </>
    );
}
 
export default BridgeDemo;